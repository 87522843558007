import React, {useContext, useState, useEffect} from "react"
import { Accordion, Container, Button, ListGroup, Row, Col, Modal } from "react-bootstrap"
import CharacterContext from "services/CharacterContext"
import NoticeContext from "services/NoticeContext"
import { InventoryContext } from "./CharacterItems"
import RulesetContext from "services/RulesetContext"
import {v4} from "uuid"

export function SpellTemplate({selectLabel, canSelect, spell, onSelect, allowReverse}) {
    const hasReverse = spell.name.slice(-1)[0] === "*"
    return <Accordion.Item eventKey={spell.name}>
        <Accordion.Header>
            <h6 className="brand">{spell.name}</h6> 
        </Accordion.Header>
        <Accordion.Body>
            <Button disabled={!canSelect} className={"float-end"} onClick={() => {
                const toPrepare = {...spell, reverse: false, id: v4()}
                onSelect(toPrepare)
            }}>{selectLabel}</Button>
            {hasReverse && allowReverse && <Button disabled={!canSelect} className={"float-end"} onClick={() => {
                const toPrepare = {...spell, name: spell.reversedName, id: v4()}
                onSelect(toPrepare)
            }}>Reverse</Button>}

            <p>{spell.type} Level {spell.level}</p>
            <p>Duration: {spell.duration}</p>
            <p>Range: {spell.range}</p>
            <p>{spell.description}</p>
        </Accordion.Body>
    </Accordion.Item>
}

export function SpellsActionPanel({character, updateCharacter}) {
    const [show, setShow] = useState(false)
    const [showSpell, setShowSpell] = useState(false)

    return <>
        <a href="#" onClick={() => setShow(true)}>Manage Spells</a>
        <ListGroup>
            <ListGroup.Item>
                <Row>
                    <Col xs={1}><h6 className="brand">Level</h6></Col>
                    <Col xs={9}><h6 className="brand">Spells</h6></Col>
                    <Col xs={2}><h6 className="brand">Action</h6></Col>
                </Row>
            </ListGroup.Item>
            {(character.skills.spells || []).map((spell) => {
                return <ListGroup.Item key={spell.id}>
                    <Row>
                        <Col xs={1}>{spell.level}</Col>
                        <Col xs={9}>{spell.name}</Col>
                        <Col xs={1}><Button variant="warning" onClick={() => {
                            setShowSpell(spell)
                        }}>View</Button></Col>
                        <Col xs={1}><Button variant="danger" onClick={() => {
                            const spells = character.skills.spells.filter((s) => s.id !== spell.id)
                            updateCharacter(character, "skills", {spells})
                        }}>Cast</Button></Col>
                    </Row>
                </ListGroup.Item>
            })}
        </ListGroup>
        <Modal size="lg" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Manage Spell Book</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CharacterSpells />
            </Modal.Body>
        </Modal>
        <Modal size="lg" show={showSpell} onHide={() => setShowSpell(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{showSpell?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{showSpell?.description}</p>
            </Modal.Body>
        </Modal>
    </>
}

export default function CharacterSpells() {
    const {sources} = useContext(RulesetContext)
    const [spells, setSpells] = useState(null)
    const {addItem, saveItem, items, market} = useContext(InventoryContext)
    const [spellBook, setSpellBook] = useState()
    const {addNotice} = useContext(NoticeContext)
    const [prepared, setPrepared] = useState(character?.skills?.spells)
    const {character, updateCharacter} = useContext(CharacterContext)
    const currentLevel = character?.instance?.level || 1
    const spellType = character?.class?.name === "Cleric" ? "Cleric" : "Magic-User"

    useEffect(() => {
        if (character?.id && items && sources) {
            setSpells(sources.spells)
            setPrepared(character?.skills?.spells)
            setSpellBook(items.find((item) => item?.type === "Book, Spell (Blank)"))
        }
    }, [sources, character, items])

    useEffect(() => {
        if (character?.id && prepared) {
            updateCharacter(character, "skills", {spells: prepared})
        }
    }, [prepared])

    const getCurrentProgress = () => {
        return character?.class?.progression?.find(({level}) => level === currentLevel) || character?.class?.progression.slice(-1)[0]
    }
    const canPrepareMore = (inquiredLevel) => {
        const currentProgress = getCurrentProgress()
        return (prepared || []).filter(({level}) => level === parseInt(inquiredLevel, 10)).length < currentProgress.spells[inquiredLevel]
    }

    const onPrepare = (spell) => {
        if (!canPrepareMore(spell.level)) {
            const currentProgress = getCurrentProgress()
            addNotice("Error", `You can only prepare ${currentProgress.spells[spell.level]} level ${spell.level} spells.`, "danger")
            return
        }
        setPrepared([spell, ...(prepared || [])])
    }

    useEffect(() => {
        if (character?.id && spells?.length > 0 && market) {
            if (getCurrentProgress().spells && spellType === "Magic-User" && !spellBook) {
                const newSpellBook = {...(market["Miscellaneous Equipment"] || []).find(({type}) => type === "Book, Spell (Blank)"), id: v4(), spells: []}
                const readMagicSpell = spells.find(({name}) => name === "Read Magic")
                newSpellBook.spells.push({...readMagicSpell, id: v4()})
                addItem(newSpellBook)
            }
        }
    }, [spells, market])

    const onScribe = (spell) => {
        if (spellBook) {
            saveItem({...spellBook, spells: [...(spellBook?.spells || []), spell]})
        }
    }

    const currentProgress = getCurrentProgress()

    if (!character) {
        return null
    }

    if (!currentProgress?.spells) {
        return <Container>
            <h1 className="brand">{character?.settings?.name} does not possess spell casting abilities.</h1>
        </Container>
    }

    return <Container>
        <h1 className="brand">{spellType} Spells</h1>
        <ListGroup>
            <ListGroup.Item>
                <h3 className="brand">Prepared Spells</h3>
            </ListGroup.Item>
            {(prepared || []).map((s) => {
                return <ListGroup.Item key={s.id}>
                    <Row>
                        <Col>
                            <h6 className="brand">{s.name}</h6>
                        </Col>
                        <Col className="text-end">
                            <Button onClick={() => {
                                setPrepared(prepared.filter((p) => p.id !== s.id))
                            }}>Unprepare</Button>
                        </Col>
                    </Row>
                </ListGroup.Item>
            })}
        </ListGroup>
        <Accordion>
            {Object.entries(currentProgress?.spells || {}).map(([spellLevel, available], i) => {
                const canPrepare = canPrepareMore(spellLevel)
                return <Accordion.Item key={`spellLevel-${i}`} eventKey={spellLevel}>
                    <Accordion.Header><h3 className="brand">Level {spellLevel} {prepared?.filter(({level}) => {
                        return level === parseInt(spellLevel, 10)
                    })?.length}/{available}</h3></Accordion.Header>
                    <Accordion.Body>
                        {spellType === "Magic-User" && <>
                            <h4 className="brand">Spell Book</h4>
                            <p>
                                You may prepare any spells you have scribed in spell book.                                
                            </p>
                            <Accordion>
                                {(spellBook?.spells || []).filter(({level, type}) => level == spellLevel && type === spellType).map((s) => {
                                    return <SpellTemplate selectLabel={"Prepare"} canSelect={canPrepare} key={s.name} allowReverse={true} spell={s} onSelect={onPrepare} />
                                })}
                            </Accordion></>}
                        <h4 className="brand">Spells</h4>
                        <Accordion>
                            {(spells || []).filter(({level, type}) => level == spellLevel && type === spellType).map((s) => {
                                return <SpellTemplate selectLabel={spellType === "Magic-User" ? "Scribe" : "Prepare"} canSelect={spellType === "Magic-User" ? true : canPrepare} key={s.name} allowReverse={spellType !== "Magic-User"} spell={s} onSelect={spellType === "Magic-User" ? onScribe : onPrepare} />
                            })}
                        </Accordion>
                    </Accordion.Body>
                </Accordion.Item>
            })}
        </Accordion>
    </Container>
}