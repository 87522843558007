import React from "react"
import characters from "../assets/characters.png"
import campaign from "../assets/campaign-cover.png"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { useAccordionButton } from "react-bootstrap/AccordionButton"
import Accordion from "react-bootstrap/Accordion"
import CallToAction from "./CallToAction"

export default function Collections() {
    const decoratedOnClick = useAccordionButton("collections")
    const decoratedHide = useAccordionButton("none")
    return (<Row onMouseEnter={decoratedOnClick} onMouseLeave={decoratedHide}>
        <Accordion.Collapse eventKey="collections"  className={"text-light"}>
            <Container>
                <Row className={"justify-content-around"}>
                    <CallToAction path="/characters" image={characters} text="My Characters"/>
                    <CallToAction path="/campaign" image={campaign} text="My Campaigns"/>
                </Row>
            </Container>
        </Accordion.Collapse>
    </Row>
    )
}