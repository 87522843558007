import React, {useContext, useState} from "react"
import { useEffect } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import CharacterContext from "services/CharacterContext"

export default function CharacterTraits() {
    const {character, updateCharacter} = useContext(CharacterContext)

    const [alignment, setAlignment] = useState(character?.traits?.alignment || "")
    const [personality, setPersonality] = useState(character?.traits?.personality || "")
    const [appearance, setAppearance] = useState(character?.traits?.appearance || "")
    const [age, setAge] = useState(character?.traits?.age || "")
    const [height, setHeight] = useState(character?.traits?.height || "")
    const [weight, setWeight] = useState(character?.traits?.weight || "")
    const [gender, setGender] = useState(character?.traits?.gender || "")

    useEffect(() => {
        if (!character?.id) {
            return
        }
        updateCharacter(character, "traits", {
            alignment,
            personality,
            appearance,
            age,
            height,
            weight,
            gender
        })

    }, [alignment, personality, appearance, age, height, weight, gender])

    const wrapSetter = (setter) => {
        return ({target}) => {
            setter(target.value)
        }
    }

    const notChaotic = character?.class?.name === "Cleric"
    const notNeutral = ["Cleric", "Magic-User", "Elf"].includes(character?.class?.name)
    const notLawful = ["Magic-User", "Elf"].includes(character?.class?.name)

    if (!character) return null

    return <Container>
        <h1 className={"brand"}>Traits</h1>
        <p>These are the features that set your character apart in the fiction of the world. They will likely have little or no impact on the game mechanics.</p>
        <Row>
            <Col>
                <h4 className={"brand"}>Alignment</h4><Form.Select onChange={wrapSetter(setAlignment)}>
                    <option>Select an Alignments...</option>
                    <option disabled={notLawful} selected={alignment === "Lawful"} value={"Lawful"}>Lawful</option>
                    <option disabled={notNeutral} selected={alignment === "Neutral"} value={"Neutral"}>Neutral</option>
                    <option disabled={notChaotic} selected={alignment === "Chaotic"} value={"Chaotic"}>Chaotic</option>
                </Form.Select>
            </Col>
            <Col>        
                <h4 className={"brand"}>Personality</h4><Form.Control value={personality} type={"text"} onChange={wrapSetter(setPersonality)}  />
            </Col>
        </Row>
        <Row>
            <Col>
                <h4 className={"brand"}>Gender</h4><Form.Control value={gender} type={"text"} onChange={wrapSetter(setGender)}  />
            </Col>
            <Col>
                <h4 className={"brand"}>Age</h4><Form.Control value={age} type={"text"} onChange={wrapSetter(setAge)}  />
            </Col>
        </Row>
        <Row>
            <Col>
                <h4 className={"brand"}>Height</h4><Form.Control value={height} type={"text"} onChange={wrapSetter(setHeight)}  />
            </Col>
            <Col>
                <h4 className={"brand"}>Weight</h4><Form.Control value={weight} type={"text"} onChange={wrapSetter(setWeight)}  />
            </Col>
        </Row>
        <Row>
            <Col>        
                <h4 className={"brand"}>Appearance</h4><Form.Control value={appearance} as={"textarea"} rows={3} onChange={wrapSetter(setAppearance)}  />
            </Col>
        </Row>
    </Container>
}