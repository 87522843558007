import React, { useContext } from "react"
import {useState, useEffect} from "react"
import { Col, Row, Container, Tabs, Tab, ListGroup, Button } from "react-bootstrap"
import {
    useParams
} from "react-router-dom"
import ClientContext from "services/ajax"
import {format} from "date-fns"

function UserManager() {
    const {admin: {getMailchimpSubscribers, getUsers}} = useContext(ClientContext)    
    const [subscribers, setSubscribers] = useState([])
    const [subCount, setSubCount] = useState(0)
    const [users, setUsers] = useState([])
    const [subSort, setSubSort] = useState("email_address")
    const [dir, setDir] = useState(1)


    useEffect(() => {
        getUsers().then((u) => {
            setUsers(u)
        })
        getMailchimpSubscribers().then((s) => {
            setSubscribers(s.members)
            setSubCount(s.total_items)
        })
    }, [])

    const icons = {
        subscribed: <i className={"bi-check text-success"}/>,
        pending: <i className={"bi-hourglass-split text-warning"}/>,
        unsubscribed: <i className={"bi-x text-danger"}/>,
    }

    return <Container>
        <h1>User Manager</h1>
        <Row>
            <Col>
                <h3>Mailchimp Subscribers ({subCount})</h3>
                <ListGroup>
                    <ListGroup.Item>
                        <Row>
                            <Col xs={6} onClick={() => {
                                setSubSort("email_address")
                                setDir(dir * -1)
                            }}>Email</Col>
                            <Col xs={2} onClick={() => {
                                setSubSort("status")
                                setDir(dir * -1)
                            }}>Status</Col>
                            <Col xs={2} onClick={() => {
                                setSubSort("timestamp_signup")
                                setDir(dir * -1)
                            }}>Date</Col>
                            <Col xs={2}>Actions</Col>
                        </Row>
                    </ListGroup.Item>
                    {subscribers.map((s) => {
                        console.log(s)
                        return {
                            ...s,
                            email_address: s.email_address.toLowerCase()
                        }
                    }).sort((a, b) => {
                        if (a[subSort] < b[subSort]) {
                            return -1 * dir
                        } else if (a[subSort] > b[subSort]) {
                            return 1 * dir
                        } else {
                            return 0
                        }
                    }).map((s) => {
                        return <ListGroup.Item className={users.find(({email}) => email === s.email_address) ? "bg-success" : ""} key={s.id}>
                            <Row>
                                <Col xs={6}>{s.email_address}</Col>
                                <Col xs={2}>{icons[s.status] }</Col>
                                <Col xs={2}>{format(Date.parse(s.timestamp_signup), "MM-dd-yy")}</Col>
                                <Col xs={2}><Button>Invite</Button></Col>
                            </Row>
                        </ListGroup.Item>
                    })}
                </ListGroup>
            </Col>
            <Col>
                <h3>Registered Users</h3>
                <ListGroup>
                    <ListGroup.Item>
                        <Row>
                            <Col>Email</Col>
                            <Col># Characters</Col>
                            <Col>Actions</Col>
                        </Row>
                    </ListGroup.Item>
                    {users.map((u) => <ListGroup.Item key={u.public}>
                        <Row>
                            <Col>{u.email}</Col>
                            <Col>{u.characterCount} <i className="bi-person-circle"/></Col>
                            <Col><Button>Edit</Button></Col>
                        </Row>
                    </ListGroup.Item>)}
                </ListGroup>

            </Col>
        </Row>
    </Container>
}

function DatabaseManager() {
    const [tables, setTables] = useState([])
    const [migrations, setMigrations] = useState([])
    const {admin} = useContext(ClientContext)
    useEffect(() => {
        admin.getTables().then(tables => setTables(tables))
        admin.getMigrations().then(migrations => setMigrations(migrations))
    }, [])

    return <>
        <Button className='mt-3' onClick={admin.runSeed}>Run Seed</Button>
        <ListGroup className="mt-3">
            {Object.entries(tables).map(([table, count], i) => <ListGroup.Item key={`table-${i}`}>{table} [{count}]</ListGroup.Item>)}
        </ListGroup>
        <ListGroup className="mt-3">
            <ListGroup.Item><Button onClick={() => {
                admin.migrateUp().then(() => window.location.reload())
            }}><i className={"bi-arrow-up"}/></Button><Button onClick={() => {
                admin.migrateDown().then(() => window.location.reload())
            }}><i className={"bi-arrow-down"}/></Button></ListGroup.Item>
            {migrations.map(({name, hasRun}, i) => <ListGroup.Item key={`table-${i}`}>{name} {hasRun ? <i className={"bi-check text-success"}/> : null}</ListGroup.Item>)}
        </ListGroup>
    </>
}

export default function Admin() {
    const {subpage} = useParams()
    return <Container>
        <Tabs activeKey={subpage || "users"} onSelect={(k) => window.location.assign(`/admin/${k}`)}>
            <Tab eventKey="users" title="Users">
                <UserManager/>        
            </Tab>
            <Tab eventKey="databases" title="Database">
                <DatabaseManager/>
            </Tab>
        </Tabs>
    </Container>
}