import React, {createContext, useContext, useEffect, useMemo, useState} from "react"
import ClientContext from "./ajax"

const UserContext = createContext(null)

export function UserContextProvider ({children}) {
    const {checkToken, getCurrentUser} = useContext(ClientContext)
    const [user, setUser] = useState(getCurrentUser())
  
    useEffect(() => {
        checkToken().then(setUser)
    }, [checkToken])

    useEffect(() => {
        setUser(getCurrentUser())
    }, [getCurrentUser])

    const value = useMemo(() => {
        return {user, setUser}
    }, [user])

    return <UserContext.Provider value={value}>
        {children}
    </UserContext.Provider>
}

export default  UserContext
