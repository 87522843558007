import React, {useContext, useState, useEffect} from "react"
import SelectList from "components/SelectList"
import ClassTemplate from "./ClassTemplate"
import CharacterContext from "services/CharacterContext"
import {Button, Container} from "react-bootstrap"
import DiceRoller from "components/DiceRoller"
import RulesetContext from "services/RulesetContext"
import { signedPrefix } from "services/util"

CharacterClass.label = "Class"

export default function CharacterClass() {
    const {character, updateCharacter} = useContext(CharacterContext)
    const {sources} = useContext(RulesetContext)
    const {level, hp} = character?.instance || {}
    const {progression, minimumHp} = character?.class || {}
    const progressionLevel = progression?.find(({level: l}) => l === level || 1)
    const [hpMax, setHpMax] = useState(hp?.max || 0)

    useEffect(() => {
        if (character?.id && hpMax > 0 && hpMax !== hp.max) {
            let levels = character?.instance?.hp?.levels
            if (levels) {
                levels[level - 1] = hpMax
            } else {
                levels = [hpMax]
            }
            updateCharacter(character, "instance", {hp: {max: levels.reduce((m,i) => m + i, 0), current: hpMax, levels }})
        }
    }, [hpMax])

    const rollHp = (result) => {
        const {modifier} = character?.attributes?.constitution || {modifier: "0"}
        const newHp = Math.max(minimumHp, result.value + parseInt((modifier || "").replace("+", ""), 10))
        setHpMax(newHp)
    }

    if (character?.class?.name) {
        return <Container className={"text-start"}>
            <h1 className="brand">Class</h1>
            <h2 className="brand">{character?.class?.name} <Button onClick={() => updateCharacter(character, "class", {name: null, description: null, progression: null})}>Remove</Button></h2>
            <div dangerouslySetInnerHTML={{__html: character.class.description}}></div>
            {progression.filter(p => p.level <= level).map((prog) => {
                return <div key={`level-${prog.level}`}>Starting HP: {((hp.levels || [])[prog.level - 1]) ? 
                    <h2 className={"d-inline-block"}> {(hp.levels || [])[prog.level - 1]} <Button onClick={() => setHpMax(null)}>Reset</Button></h2> : 
                    <DiceRoller button label={`Roll ${progressionLevel.hp}`} d={progressionLevel.hp} onResult={rollHp}/>
                }</div>
            })}
        </Container>
    } else if (sources) {
        return <>
            <h1 className="brand">Class</h1>
            <SelectList items={sources.classes} selectedItem={character.class} ItemTemplate={ClassTemplate} onSelect={(cls) => {
                updateCharacter(character, "class", cls)
                updateCharacter(character, "instance", {xp: {current: 0, next: cls.progression[1].experience}})
                if (cls.name === "Halfling") {
                    const dexMod = parseInt(character.attributes.dexterity.modifier.replace("+", ""), 10)
                    updateCharacter(character, "attributes", {dexterity: {
                        value: character.attributes.dexterity.value, 
                        modifier: signedPrefix(dexMod + 1)
                    }})
                } else if (cls.name === "Dwarf") {
                    const conMod = parseInt(character.attributes.constitution.modifier.replace("+", ""), 10)
                    updateCharacter(character, "attributes", {constitution: {
                        value: character.attributes.constitution.value, 
                        modifier: signedPrefix(conMod + 1)
                    }})
                }
            }} />
        </>
    } else {
        return null
    }
}