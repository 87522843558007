import React from "react"
import ReactImageUploading from "react-images-uploading"
import Button from "react-bootstrap/Button"
import CloseButton from "react-bootstrap/CloseButton"

export default function ImageManager({images, onChange, onImageSelect, removeImage, selectionSaved}) {
    const borderColor = selectionSaved ? "success" : "warning"
    return (<ReactImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={10}
        dataURLKey="preview"
    >
        {({
            imageList,
            onImageUpload,
            isDragging,
            dragProps
        }) => {
            return (
                <div className="upload__image-wrapper">
                    <Button
                        style={isDragging ? { color: "red"} : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                    >Click or Drop here</Button>{" "}
                    <div className="hstack">
                        {imageList.map((image, index) => (
                            <div 
                                key={index} 
                                className={["position-relative bg-white m-1", image.selected ? `border border-${borderColor} border-2` : "border-0"].join(" ")}
                                style={{
                                    width: "5em", 
                                    height: "5em",
                                    backgroundImage: `url(${image["preview"]})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                onClick={() => onImageSelect(index)}  
                            >
                                <CloseButton as={"a"} className={"position-absolute top-0 end-0"} onClick={(e) => {
                                    e.stopPropagation()
                                    removeImage(index)
                                }} />
                            </div>
                        ))}
                    </div>
                </div>
            )}}
    </ReactImageUploading>)
}
