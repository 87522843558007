import React, { useContext } from "react"
import defaultAvatar from "assets/avatar.png"
import Dropdown from "react-bootstrap/Dropdown"
import UserContext from "services/UserContext"

const sizes = {
    xs: {height: 20, width: 20},
    sm: {height: 30, width: 30},
    md: {height: 40, width: 40},
    lg: {height: 50, width: 50},
    xl: {height: 80, width: 80},
    xxl: {height: 100, width: 100}
}

export function CharacterControlContainer({character, user, children}) {
    return <Dropdown align="end" variant={"dark"}  id='dropdown-avatar'>
        <Dropdown.Toggle variant={"outline-dark"} className={"d-flex"}>
            {children}
        </Dropdown.Toggle>
        <Dropdown.Menu className={user.id === character.owner_id ? "" : "d-none"}>
            <Dropdown.Item href={`/character/${character.id}`}>View</Dropdown.Item>
            <Dropdown.Item href={`/characters/builder/${character.system}/${character.build}/${character.id}`}>Edit</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
}

export function UserControlContainer({user, children}) {
    return <Dropdown align="end" variant={"dark"}  id='dropdown-avatar'>
        <Dropdown.Toggle variant={"black"} className={"d-flex"}>
            {children}
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Item href="/profile">Profile</Dropdown.Item>
            <Dropdown.Item hidden={!user?.roles?.includes("administrator")} href="/admin">Admin</Dropdown.Item>
            <Dropdown.Item onClick={(e) => {
                e.preventDefault()
                localStorage.setItem("user", null)
                window.location.assign("/")
            }} href="/logout">Logout</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
}

export function CharacterAvatar({character, xs, sm, md, lg, xl, xxl, className}) {
    const size = sizes[xs ? "xs" : sm ? "sm" : md ? "md" : lg ? "lg" : xl ? "xl" : xxl ? "xxl" : "md"]

    return <div className={`d-inline-block text-light rounded-circle align-middle ${className}`} style={{
        ...size, 
        backgroundColor: "#666",
        backgroundImage: `url(${character?.settings?.avatar || defaultAvatar})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
    }}></div>
}

export function UserAvatar({user, xs, sm, md, lg, xl, xxl}) {
    let renderUser = user
    if (!renderUser) {
        renderUser = useContext(UserContext).user
    }
    const size = sizes[xs ? "xs" : sm ? "sm" : md ? "md" : lg ? "lg" : xl ? "xl" : xxl ? "xxl" : "md"]
    return <div className={"d-flex text-light  rounded-circle align-middle"} style={{
        ...size,
        backgroundColor: "#666",
        backgroundImage: `url(${renderUser.avatar || defaultAvatar})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
    }}></div>
}