import React, { Suspense, lazy } from "react"
import { useParams } from "react-router-dom"
import { Placeholder } from "react-bootstrap"
import {CharacterContextProvider} from "services/CharacterContext"

export default function CharacterBuilder() {
    const {build, system} = useParams()
    const Component = lazy(() => import(`../components/${system}/CreateCharacter`))


    return <CharacterContextProvider>
        <Suspense fallback={<Placeholder/>}>
            <Component system={system} build={build}/>
        </Suspense>
    </CharacterContextProvider>
}