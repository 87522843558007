import React from "react"
import { Col, Row, ListGroup, Accordion, Button } from "react-bootstrap"

export function SkillTemplate({skill, children}) {
    const {name, rank} = skill
    return <><Col>{name} </Col><Col>{rank} in 6 </Col>{children}</>
}

export function ProgressionHeaderTemplate({progression}) {
    const {spells, skills, skillPoints} = progression.slice(-1)[0]
    return <ListGroup.Item>
        <Row>
            <Col xs={1}>Level</Col>
            <Col xs={2}>Experience</Col>
            <Col xs={1}>HP</Col>
            <Col xs={3}>P/P/B/D/M</Col>
            {spells && <Col xs={4}>{Object.keys(spells).join(" | ")}</Col>}
            {skills && <Col>Skills</Col>}
            {skillPoints && <Col>Skill Points</Col>}
        </Row>
    </ListGroup.Item>
}

export function ProgressionTemplate({level, experience, hp, saves, spells, skills, skillPoints}) {
    return <ListGroup.Item>
        <Row>
            <Col xs={1}>{level}</Col>
            <Col xs={2}>{experience}</Col>
            <Col xs={1}>{hp}</Col>
            <Col xs={3}>{saves.paralyze}/{saves.poison}/{saves.breath}/{saves.device}/{saves.magic}</Col>
            {spells && <Col xs={4}>{Object.values(spells).join(" | ")}</Col>}
            {skills && <Col>{skills.map((skill, i) => <SkillTemplate key={`skill-${i}`} skill={skill}/>)}</Col>}
            {skillPoints && <Col>{skillPoints}</Col>}
        </Row>
    </ListGroup.Item>
}

export function SourceTemplate({source}) {
    const {title, publisher} = source
    return <div>{title} by {publisher}</div>
}

export default function ClassTemplate({item, selectedElement, selectedItem, onSelect}) {
    const {name, description, progression, source} = item
    return <Accordion.Item eventKey={name} ref={item.name === selectedItem?.name ? selectedElement : null}>
        <Accordion.Header><h3 className="brand">{name}</h3> <span className={"ms-3 text-decoration-none"}/><SourceTemplate source={source}></SourceTemplate></Accordion.Header>
        <Accordion.Body>
            <div dangerouslySetInnerHTML={{__html: description}}></div>
            <ListGroup>
                <ProgressionHeaderTemplate progression={progression}></ProgressionHeaderTemplate>
                {progression.map((p, i) => <ProgressionTemplate key={`progression-${i}`} {...p}></ProgressionTemplate>)}
            </ListGroup>
            <Button variant='primary' onClick={() => {
                onSelect(item)
            }}>Select</Button>
        </Accordion.Body>
    </Accordion.Item>
}