import React, {useContext, useEffect, useState} from "react"
import DiceRoller from "components/DiceRoller"
import { Container, Button, Col, Row } from "react-bootstrap"
import Swappable from "components/Swappable"
import CharacterContext from "services/CharacterContext"
import { capitalCase } from "services/util"

export function AttributesTemplate({attributes, setters, rolling}) {
    return Object.entries(attributes).sort(([key1], [key2]) => key1 > key2 ? 1 : -1).map(([key, value]) => {
        return <Col xs={4} md={3} lg={2} className={"d-flex justify-content-center align-items-center flex-column"} key={key}>
            <h6 className={"brand d-flex justify-content-center align-items-center p-2 bg-white border border-1 border-primary rounded-4"}>{capitalCase(key)}</h6>
            <h2 className={"brand d-flex justify-content-center align-items-center p-2 bg-white border-top border-bottom border-2 border-primary rounded-2"} style={{marginTop: -10, marginBottom: -10}}>{value?.value}</h2>
            <h5 className={"brand bg-white border border-1 border-primary rounded-circle d-flex justify-content-center align-items-center"} style={{height: 40, width: 40, aspectRatio: 1}}>{value?.modifier}</h5>
            {rolling && <DiceRoller label={"Roll"} value={value?.value} button keys={[key]} d="3d6" onResult={(result) => { 
                setters[key](result.value) 
            } } />}
        </Col>
    })
}

CharacterAttributes.label = "Attributes"

export default function CharacterAttributes() {
    const {character, updateCharacter} = useContext(CharacterContext)

    const [state, setState] = useState("roll")
    const [attributes, setAttributes] = useState(character?.attributes)   
    const [charisma, setCharisma] = useState(character?.attributes?.charisma)
    const [constitution, setConstitution] = useState(character?.attributes?.constitution)
    const [dexterity, setDexterity] = useState(character?.attributes?.dexterity)
    const [intelligence, setIntelligence] = useState(character?.attributes?.intelligence)
    const [strength, setStrength] = useState(character?.attributes?.strength)
    const [wisdom, setWisdom] = useState(character?.attributes?.wisdom)
    const [swapsLeft, setSwapsLeft] = useState(character?.instance?.swapsLeft || 1)

    const modifier = (score) => {
        if (score <= 3) {
            return "-3"
        } else if (score < 6) {
            return "-2"
        } else if (score < 9) {
            return "-1"
        } else if (score < 13) {
            return "0"
        } else if (score < 16) {
            return "+1"
        } else if (score < 18) {
            return "+2"
        } else if (score < 21) {
            return "+3"
        }
    }

    useEffect(() => {
        if (!(charisma && constitution && dexterity && intelligence && strength && wisdom)) {
            setState("roll")
        } else if (swapsLeft > 0) {
            if (state !== "swap") {
                setState("evaluate")
            }
        } else {
            setState("save")
        }
    }, [state])

    useEffect(() => {
        updateCharacter(character, "attributes", attributes)
    }, [attributes])

    useEffect(() => {
        updateCharacter(character, "instance", {swapsLeft: swapsLeft})
    }, [swapsLeft])

    useEffect(() => {
        setAttributes({
            charisma: charisma,
            constitution: constitution,
            dexterity: dexterity,
            intelligence: intelligence,
            strength: strength,
            wisdom: wisdom
        })
        if (charisma && constitution && dexterity && intelligence && strength && wisdom) {
            setState("evaluate")
        }
    }, [charisma, constitution, dexterity, intelligence, strength, wisdom])

    const resetAttributes = () => {
        setCharisma(null)
        setConstitution(null)
        setDexterity(null)
        setIntelligence(null)
        setStrength(null)
        setWisdom(null)
        setAttributes({
            charisma: null,
            constitution: null,
            dexterity: null,
            intelligence: null,
            strength: null,
            wisdom: null
        })
        setSwapsLeft(1)
        setState("roll")
    }

    const getTotalModifier = () => {
        return Object.values(attributes).reduce((m, n) => m + parseInt(n.modifier, 10), 0)
    }

    const wrapSetterWithModifiers = (setter) => {
        return (value) => {
            setter({value, modifier: modifier(value)})
        }
    }

    return <Container>
        <h1 className={"brand"}>Attributes</h1>
        {state === "roll" && <Row>
            <DiceRoller button keys={["attributes"]} label="Roll Attributes" d="18d6" onResult={(result) => {
                wrapSetterWithModifiers(setCharisma)(result.rolls.slice(0, 3).reduce((m, n) => m + n.value, 0))
                wrapSetterWithModifiers(setConstitution)(result.rolls.slice(3, 6).reduce((m, n) => m + n.value, 0))
                wrapSetterWithModifiers(setDexterity)(result.rolls.slice(7, 9).reduce((m, n) => m + n.value, 0))
                wrapSetterWithModifiers(setIntelligence)(result.rolls.slice(9, 12).reduce((m, n) => m + n.value, 0))
                wrapSetterWithModifiers(setStrength)(result.rolls.slice(12, 15).reduce((m, n) => m + n.value, 0))
                wrapSetterWithModifiers(setWisdom)(result.rolls.slice(15, 18).reduce((m, n) => m + n.value, 0))
            } } />
            <AttributesTemplate attributes={{charisma, constitution, dexterity, intelligence, strength, wisdom}} setters={{
                charisma: wrapSetterWithModifiers(setCharisma),
                constitution: wrapSetterWithModifiers(setConstitution),
                dexterity: wrapSetterWithModifiers(setDexterity),
                intelligence: wrapSetterWithModifiers(setIntelligence),
                strength: wrapSetterWithModifiers(setStrength),
                wisdom: wrapSetterWithModifiers(setWisdom)
            }} rolling={true}/>
        </Row>}
        {state === "evaluate" && <div className={getTotalModifier() < 0 ? "bg-danger" : ""}>
            Total Modifiers: {getTotalModifier()}
            <Button onClick={() => {
                setAttributes({...attributes})
                setSwapsLeft(0)
                setState("save")
            }}>Accept</Button>
            <Button onClick={() => {setState("swap")}}>Swap</Button>
            <Button onClick={resetAttributes}>Reset</Button>

            <Row>
                <AttributesTemplate attributes={attributes}/>
            </Row>
        </div>}
        {state === "swap" && <Swappable attributes={attributes} onSwap={(attributes) => {
            setSwapsLeft(swapsLeft - 1)
            setAttributes({...attributes})
            setState("evaluate")
        }}/>}
        {state === "save" && <>
            <Row>
                <Button onClick={resetAttributes}>Reset</Button>
                <AttributesTemplate attributes={attributes}/>
            </Row>
        </>}
    </Container>
}