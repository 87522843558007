import React, {useContext} from "react"
import { useParams } from "react-router-dom"
import CharacterContext from "services/CharacterContext"
import { Container } from "react-bootstrap"

export default function StepWizard({ system, build, children, stepNavigator }) {
    const { step } = useParams()
    const { character } = useContext(CharacterContext)

    const currentStep = parseInt(step, 10)
    const max = children.length
    return (<>
        <Container fluid className={"d-flex sticky-top justify-content-between"}>
            <button className={"btn btn-lg text-primary"}
                disabled={!character?.id}
                onClick={() => {
                    let nextStep = currentStep - 1
                    while(nextStep >= 0 && !children[nextStep]) {
                        nextStep--
                    }
                    window.location.assign(`/characters/builder/${system}/${build}/${character.id}/${Math.max(nextStep, 0)}`)
                }}
            >
                <i className={"bi-arrow-left-circle-fill"}/>
            </button>
            {stepNavigator}
            <button className={"btn btn-lg text-primary"}
                disabled={!character?.id}
                onClick={() => {
                    let nextStep = currentStep + 1
                    while(nextStep <= max && !children[nextStep]) {
                        nextStep++
                    }
                    if (nextStep >= max) {
                        window.location.assign(`/character/${character.id}`)
                        return 
                    }

                    window.location.assign(`/characters/builder/${system}/${build}/${character.id}/${Math.min(nextStep, max - 1)}`)
                }}
            >
                <i className={"bi-arrow-right-circle-fill"}/>
            </button>
        </Container>
        <Container>
            <div className='current-step'>
                {children[currentStep]}
            </div>
        </Container>
    </>)
}
