import ImageManager from "./ImageManager"
import React, { useState, useEffect, useContext, useRef } from "react"
import ClientContext from "services/ajax"
import API_HOST from "services/api"
import CharacterContext from "services/CharacterContext"

export function CoverSelector({id, selected, onChange}) {
    const { uploadImage, deleteImage, getRelatedImages } = useContext(ClientContext)

    const [images, setImages] = useState([])
    const [saved, setSaved] = useState(true)
    const [refresh, setRefresh] = useState(null)

    const convertImage = (image) => {
        const url = `${API_HOST}/api/image/_d/${image.owner_id}/${image.id}.${image.original_name.split(".").slice(-1)[0]}`
        return {
            ...image,
            preview: url,
            selected: selected === url
        }
    }

    useEffect(() => {
        if (id) {
            getRelatedImages(id).then((images) => {
                setImages(images.map((image) => {
                    const url = `${API_HOST}/api/image/_d/${image.owner_id}/${image.id}.${image.original_name.split(".").slice(-1)[0]}`
                    return {
                        ...image,
                        preview: url,
                        selected: selected === url
                    }
                }))
                setSaved(true)
            })    
        }
    }, [refresh, id, selected])

    return <ImageManager images={images} onChange={(images) => {
        Promise.all(images.map((image) => {
            if (image.file) {
                return uploadImage(image.file, id)
            }
            return Promise.resolve(image)
        })).then((images) => {
            setImages(images.map(convertImage))
        })
    }} removeImage={(i) => {
        const image = images[i]
        deleteImage(image.id)
        setRefresh(new Date())
    }} onImageSelect={(i) => {
        setSaved(false)
        onChange(images[i].preview)
        setRefresh(!refresh)
    }} selectionSaved={saved}/>
}

export default function AvatarSelector({data, sectionKey, keys}) {
    const mounted = useRef(false)
    const {character, updateCharacter} = useContext(CharacterContext)
    const ajax = useContext(ClientContext)
    const { uploadImage, deleteImage } = ajax

    const [images, setImages] = useState([])
    const [refresh, setRefresh] = useState(null)

    const convertImage = (image) => {
        const url = `${API_HOST}/api/image/_d/${image.owner_id}/${image.id}.${image.original_name.split(".").slice(-1)[0]}`
        return {
            ...image,
            preview: url,
            selected: character?.[sectionKey]?.[keys] === url
        }
    }
 
    useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])

    useEffect(() => {
        const [source, method] = data.split(".")
        if (source === "ajax") {
            if (character?.id) {
                ajax[method](character.id).then((images) => {
                    if (mounted.current) {
                        setImages(images.map(convertImage))
                    }
                })    
            }
        }
    }, [data, refresh, character])

    return <ImageManager images={images} onChange={(images) => {
        Promise.all(images.map((image) => {
            if (image.file) {
                return uploadImage(image.file, character.id)
            }
            return Promise.resolve(image)
        })).then((images) => {
            setImages(images.map(convertImage))
        })
    }} removeImage={(i) => {
        const image = images[i]
        deleteImage(image.id)
        setRefresh(new Date())
    }} onImageSelect={(i) => {
        const section = {...character[sectionKey]}
        section[keys] = images[i].preview
        updateCharacter(character, sectionKey, section)
    }} selectionSaved={character?.[sectionKey]?.[keys]}/>
}