import React from "react"
import { Container } from "react-bootstrap"

export default function ErrorPage() {
    const errorCode = 404
    const version = "@osrbeyond/character-app@0.0.1"
    return <Container>
        <h1>404</h1>
        <p>Whoops! We rolled a 1 on our API check. We&apos;re heading into to town to visit the blacksmith for repairs.</p>
        <p>Try again after a Short Rest.</p>
        <label>Error Code</label> <code>{errorCode}</code>
        <label>Version</label> <code>{version}</code>
    </Container>
}