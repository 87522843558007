import React, {useState, createContext, useEffect, useMemo, useContext} from "react"
import ClientContext from "./ajax"

const RulesetContext = createContext(null)

const rulesets = [
    {id: "lotfp", name: "Lamentations of the Flame Princess", active: true},
    {id: "troika", name: "Troika!", active: true},
    {id: "ose", name: "Old School Essentials", active: true},
    {id: "knave", name: "Knave", active: false},
    {id: "knave2", name: "Knave 2e", active: false},
    {id: "osric", name: "OSRIC", active: false},
    {id: "shadowdark", name: "Shadowdark", active: false},
    {id: "whitehack", name: "Whitehack", active: false},
    {id: "blackswordhack", name: "Black Sword Hack", active: false},
    {id: "blackhack", name: "The Black Hack", active: false},
    {id: "morkborg", name: "MorKborg", active: false},
    {id: "mausritter", name: "Mausritter", active: false},
    {id: "sandw", name: "Swords & Wizardry", active: false},
    {id: "bfrp", name: "Basic Fantasy Role Playing", active: false},
    {id: "bx", name: "B/X Essentials", active: false},
    {id: "wwn", name: "Worlds Without Number", active: false}
]

export function RulesetContextProvider({children}) {
    const [ruleset, setRuleset] = useState(JSON.parse(localStorage.getItem("ruleset")))
    const [sources, setSources] = useState(null)
    const [sourcesLoaded, setSourcesLoaded] = useState(false)
    const {getResources, getSource} = useContext(ClientContext)
    if (!ruleset) {
        setRuleset(rulesets[0])
    }

    useEffect(() => {
        if (ruleset) {
            console.log("loading rules")
            getResources(ruleset).then((resources) => {
                setSourcesLoaded(Promise.all(resources.map((resource) => {
                    return getSource(ruleset, resource)
                })).then((sources) => {
                    const _sources = resources.reduce((m, key, i) => {
                        m[key] = sources[i]
                        return m
                    }, {})
                    console.log(_sources)
                    setSources(_sources)
                }))
            })    
        }
    }, [ruleset])

    const value = useMemo(() => {
        return {
            ruleset, 
            setRuleset, 
            rulesets,
            sources,
            sourcesLoaded    
        }
    }, [sources, ruleset])

    return <RulesetContext.Provider value={value}>
        {children}
    </RulesetContext.Provider>
}

export default  RulesetContext