import React, {useContext} from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "App.css"
import Home from "pages/Home"
import Characters from "pages/Characters"
import Campaigns from "pages/Campaigns"
import Splash from "pages/Splash"
import CampaignBuilder from "pages/CampaignBuilder"
import CampaignEditor from "pages/CampaignEditor"
import CharacterBuilder from "pages/CharacterBuilder"
import CharacterViewer from "pages/CharacterViewer"
import Register from "pages/Register"
import Profile from "pages/Profile"
import JoinCampaign from "pages/JoinCampaign"
import Admin from "pages/Admin"
import ForgotPassword from "pages/ForgotPassword"
import SubmitRuleset from "pages/SubmitRuleset"
import Subscribe from "pages/Subscribe"
import RouteGuard from "components/RouteGuard"
import Header from "components/Header"
import Navbar from "react-bootstrap/Navbar"
import Container from "react-bootstrap/Container"
import ThemeProvider from "react-bootstrap/ThemeProvider"
import { Beyond20ContextProvider } from "services/Beyond20Context"
import { DiceProvider } from "services/DiceContext"
import {IsSavingContext, IsSavingProvider} from "services/ajax"
import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom"
import ErrorPage from "pages/Error"
import UserContext, {UserContextProvider} from "services/UserContext"
import { RulesetContextProvider } from "services/RulesetContext"
import {NoticeContextProvider} from "services/NoticeContext"
import ScrollToTop from "react-scroll-to-top"
import { ClientProvider } from "services/ajax"
import CampaignViewer from "pages/CampaignViewer"
import { CharacterContextProvider } from "services/CharacterContext"
import { StripeContextProvider } from "services/StripeContext"

const unAuthRouter = createBrowserRouter([
    {
        path: "/",
        element: <Splash/>
    },
    {
        path: "/login",
        element: <Register/>
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword/>
    },
    {
        path: "/register",
        element: <Register/>
    },
    {
        path: "/compendiums",
        element: <Splash/>
    },
    {
        path: "/characters",
        element: <Splash/>
    },
    {
        path: "/:path/*",
        element: <Register/>
    }
])

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/characters",
        element: <RouteGuard path="/characters" component={Characters}/>
    },
    {
        path: "/campaign",
        element: <RouteGuard path="/campaign" component={Campaigns}/>
    },
    {
        path: "/campaign/join/:slug",
        element: <RouteGuard path="/campaign/join/:slug" component={JoinCampaign} />
    },
    {
        path: "/profile",
        element: <RouteGuard path="/profile" component={Profile}/>
    },
    {
        path: "/characters/builder/:system?/:build?/:character_id?/:step?",
        element: <CharacterBuilder/>
    },
    {
        path: "/campaign/create",
        element: <CampaignBuilder/>
    },
    {
        path: "/character/:character_id/:tab?",
        element: <CharacterViewer/>
    },
    {
        path: "/campaign/:slug",
        element: <CampaignViewer/>
    },
    {
        path: "/campaign/:slug/edit",
        element: <CampaignEditor/>
    },
    {
        path: "/admin/:subpage?/:id?",
        element: <RouteGuard path="/admin(/:subpage)" level={"administrator"} component={Admin}/>
    },
    {
        path: "/submit-ruleset",
        element: <SubmitRuleset/>
    },
    {
        path: "/subscribe",
        element: <StripeContextProvider><Subscribe/></StripeContextProvider>
    },
    {
        path: "/subscribed",
        element: <StripeContextProvider><Subscribe/></StripeContextProvider>
    }
])

function IsSaving({isSaving}) {
    return <div className={`${isSaving ? "d-block" : "d-none"} position-fixed bottom-0 end-0 p-3 is-saving`} style={{zIndex: 1040}}>
        <i className={"bi-arrow-repeat"} />
    </div>
}


function Footer() {
    const {isSaving} = useContext(IsSavingContext)
    return <Navbar className='mt-auto' variant='dark' bg="dark" fixed="bottom">
        <Container className="text-light">
            OSRBeyond is Copyright 2023 Quest Giver Games LTD <IsSaving isSaving={isSaving}/>
        </Container>
    </Navbar>
}

function Content() {
    const {user} = useContext(UserContext)
    return <>
        {!user && <RouterProvider router={unAuthRouter}/>}
        {user && <RulesetContextProvider>
            <DiceProvider>
                <Header user={user}/>
                <Container fluid className="vstack gap-3 pb-5">
                    <CharacterContextProvider>
                        <RouterProvider router={router}/>
                    </CharacterContextProvider>
                </Container>
                <ScrollToTop smooth/>
                <Footer/>
            </DiceProvider>
        </RulesetContextProvider>}
    </>

}

function App() {
    return (
        <ThemeProvider value="dark">
            <Beyond20ContextProvider>
                <NoticeContextProvider>
                    <IsSavingProvider>
                        <ClientProvider>
                            <UserContextProvider>
                                <Content/>
                            </UserContextProvider>
                        </ClientProvider>
                    </IsSavingProvider>
                </NoticeContextProvider>
            </Beyond20ContextProvider>
        </ThemeProvider>
    )
}

export default App
