import React from "react"
import Card from "react-bootstrap/Card"

export default function CallToAction({path, image, text}) {
    return <Card role="button" onClick={() => {window.location.assign(path)}} className={"m-1 p-0 border-0"} style={{
        width: "16em", 
        height: "9em", 
        backgroundImage: `url(${image})`, 
        backgroundPosition: "center", 
        backgroundSize: "cover",
    }}>
        <Card.Text className="text-light text-center fs-3 position-absolute bg-dark w-100 bottom-0 rounded-bottom" style={{"--bs-bg-opacity": 0.5}}>{text}</Card.Text>
    </Card>

}