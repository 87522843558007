import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import RulesetContext from "services/RulesetContext"
import React, {useContext} from "react"
import {useMediaQuery} from "react-responsive"

export default function RuleSetSelector() {
    const {ruleset, setRuleset, rulesets} = useContext(RulesetContext)
    const isMobile = useMediaQuery({maxWidth: 1224})
    return <DropdownButton variant={"black"} className={"text-light"} id="dropdown-ruleset" title={<span className={`fs-${isMobile ? 6 : 2} brand`}>{ruleset?.name}</span> || "Change Rule Set"}>
        {rulesets.map((_ruleset) => {
            return <Dropdown.Item key={`selector-${_ruleset.id}`} disabled={!_ruleset.active} onClick={() => {
                localStorage.setItem("ruleset", JSON.stringify(_ruleset))
                setRuleset(_ruleset)
            }}>{_ruleset.name} {_ruleset.active ? "" : <span className={"text-danger"}>Coming Soon!</span>}</Dropdown.Item>
        })}
    </DropdownButton>
}