import React, {useContext} from "react"
import { Container, Navbar, Row, Col, InputGroup, Form, Button } from "react-bootstrap"
import characters from "../assets/characters.png"
import campaigns from "../assets/campaign-cover.png"
import whoweare from "../assets/whoweare.png"
import ClientContext from "services/ajax"

export default function Splash() {
    const { emailSignup } = useContext(ClientContext)
    const [email, setEmail] = React.useState("")
    const [isSignedUp, setIsSignedUp] = React.useState(localStorage.getItem("signedUp"))

    return <Container className={"p-0"} fluid>
        <Row>
            <Col className={"text-center"}>
                <Navbar bg="black" variant="dark" fixed="top">
                    <Navbar.Brand href="/" className={"d-flex brand flex-wrap justify-align-middle"} style={{
                        backgroundImage: "url(/logo512.png)",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        color: "#2c99ce",
                        fontSize: "2em",
                        paddingLeft: 70
                    }}><span>Beyond</span></Navbar.Brand>
                </Navbar>

            </Col>
        </Row>
        <Container fluid className={"splash"}>
            <Row className={"justify-content-center align-items-center"} style={{
                height: "100%",
            }}>
                <Col className={"text-center"}>
                    <div style={{
                        textShadow: "2px 2px 4px #000000"
                    }}>
                        <h1 className={"text-light brand"}>Welcome to the world of OSR Beyond</h1>
                        <p className={"text-light"}>Bringing high quality digital tools to your favorite OSR games.</p>
                    </div>
                    {!isSignedUp && <InputGroup className={"mx-auto"}>
                        <InputGroup.Text id="basic-addon1">Stay Informed</InputGroup.Text>
                        <Form.Control type="text" placeholder="Email" val={email} onChange={({target}) => setEmail(target.value)} aria-label="Search" aria-describedby="basic-addon1" />
                        <Button variant="primary" onClick={() => {
                            emailSignup(email).then(() => {
                                sessionStorage.setItem("signedUp", true)
                                setIsSignedUp(true)
                            })
                        }}>Sign Up</Button>
                    </InputGroup>}
                    {isSignedUp && <h3 className={"text-light"}>Thanks for signing up!</h3>}
                </Col>
            </Row>
        </Container>
        <Container fluid className={"bg-black text-light h-auto"}>
            <Container>
                <Row className={"pt-5"}>
                    <Col className={"d-flex flex-column"}>
                        <div className={"d-block w-100 rounded-3"} style={{backgroundImage: `url(${characters})`, backgroundPosition: "center", backgroundSize: "cover", height: 160}}/>
                        <h2 className={"brand"}>Character Lockers</h2>
                        <p>We are designing character creation wizards and robust character lockers for a variety of OSR games. Our character creation process really highlights the rules-light vibes of the OSR!</p>
                    </Col>
                    <Col className={"d-flex flex-column"}>
                        <div className={"h-100 w-100 rounded-3"} style={{backgroundImage: `url(${campaigns})`, backgroundPosition: "center", backgroundSize: "cover", height: 160}}/>
                        <h2 className={"brand"}>Campaign Manager</h2>
                        <p>The convenience of having all the character sheets in one place, and a handy link to invite your new players in! Super convenient for GM&apos;s running multiple games with different systems.</p>
                    </Col>
                    <Col className={"d-flex flex-column"}>
                        <div className={"h-100 w-100 rounded-3"} style={{backgroundImage: `url(${whoweare})`, backgroundPosition: "center", backgroundSize: "cover", height: 160}}/>
                        <h2 className={"brand"}>Who We Are</h2>
                        <p>Quest Giver Games LTD. is a Canadian company that has been creating TTRPG products since 2019. We have enthusiastically been playing OSR since it was the only version and have loved all the new takes on old ideas.</p>
                    </Col>
                </Row>    
                <Row>
                    <Col>
                        <h1 className={"brand"}>What&apos;s Next?</h1>
                        <p>We are currently hard at work developing the Chararacter Locker and Campaign Manager for Lamentations of the Flame Princess, Old School Essentials, Troika!, and Blueholme. We expect to have our closed alpha test for these features in Summer 2024. Once we have cleared those milestones we expect to engage the community about what features we should invest in next.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5>Wishlist Features</h5>
                        <ul>
                            <li>Homebrew collections and creation portal.</li>
                            <li>Game rules for each ruleset (publicly accessible).</li>
                            <li>Marketplace for digital versions of OSR content.</li>
                            <li>Encounter Builder.</li>
                            <li>Campaign Logger and Note Taking Utilities.</li>
                            <li>Mapping Utlities.</li>
                        </ul>
                    </Col>
                    <Col>
                        <h5>Wishlist Rulesets</h5>
                        <ul>
                            <li>Old School Reference and Index Compilation</li>
                            <li>Shadowdark</li>
                            <li>Knave</li>
                            <li>Mausritter</li>
                            <li>Into the Odd</li>
                            <li>Dungeon Crawl Classics</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </Container>
    </Container>
}
