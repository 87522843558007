// @eslint-disable

import React, {useState, useEffect, useContext} from "react"
import UserContext from "services/UserContext"
import ClientContext from "services/ajax"
import {Elements, AddressElement, PaymentElement, useStripe, useElements} from "@stripe/react-stripe-js"
import { Container, Row, Col, Card, Form, Modal,Button } from "react-bootstrap"
import { capitalCase, decorateCurrency } from "services/util"
import NoticeContext from "services/NoticeContext"
import StripeContext from "services/StripeContext"

const Price = ({children, disabled}) => <h3 className={`price ${disabled && "opacity-50"}`}>{children}</h3>

export function SubscriptionProduct({onClick, selectedClass, selected, active, schedule, product}) {
    return <Card onClick={onClick} className={selected && selectedClass}>
        <Card.Header>
            <h2 className="brand">{product.name}</h2>{active && "Current"}
        </Card.Header>
        <Card.Body>
            <p>{product.description}</p>
            {product.prices.map((price) => {
                console.log(price)
                return <Price key={price.id} disabled={schedule !== price.recurring.interval}>{decorateCurrency(price.unit_amount)} / {price.recurring.interval}</Price>
            })}
        </Card.Body>                    
    </Card>

}

function CheckoutForm({product_id, price_id}) {
    const {addNotice} = useContext(NoticeContext)
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [address, setAddress] = useState(null)
    const stripe = useStripe()
    const elements = useElements()
    const return_url = `${location.protocol}//${location.host}/subscribed?product_id=${product_id}&price_id=${price_id}`

    console.log(return_url)
    return <Form>
        <AddressElement options={{mode: "billing"}} onChange={(address) => {setAddress(address)}}/>
        <PaymentElement onChange={(payment_method) => {setPaymentMethod(payment_method)}}/>
        <Button onClick={() => {
            console.log(paymentMethod, address)
            const {error} = stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url
                }
            })
            if (error) {
                addNotice("Payment Error", error.message, "danger")
            }
        }}>Submit</Button>
    </Form>
}

export function Subscribed({price_id}) {
    const stripe = useStripe()
    const [message, setMessage] = useState(null)
    const {createSubscription} = useContext(ClientContext)
  
    useEffect(() => {
        if (!stripe) {
            return
        }
  
        // Retrieve the "setup_intent_client_secret" query parameter appended to
        // your return_url by Stripe.js
        const clientSecret = new URLSearchParams(window.location.search).get(
            "setup_intent_client_secret"
        )
  
        // Retrieve the SetupIntent
        stripe
            .retrieveSetupIntent(clientSecret)
            .then(({setupIntent}) => {
                // Inspect the SetupIntent `status` to indicate the status of the payment
                // to your customer.
                //
                // Some payment methods will [immediately succeed or fail][0] upon
                // confirmation, while others will first enter a `processing` state.
                //
                // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                switch (setupIntent.status) {
                case "succeeded":
                    setMessage("Success! Your payment method has been saved.")
                    if (stripe && price_id) {
                        createSubscription({price_id, payment_method: setupIntent.payment_method}).then(() => {
                            window.location.href.assign("/subscribe")
                        })
                    }
                    break
  
                case "processing":
                    setMessage("Processing payment details. We'll update you when processing is complete.")
                    break
  
                case "requires_payment_method":
                    // Redirect your user back to your payment page to attempt collecting
                    // payment again
                    setMessage("Failed to process payment details. Please try another payment method.")
                    window.location.href.assign("/subscribe")
                    break
                }
            })
    }, [stripe, price_id])
  
  
    return message
}

export default function Subscribe() {
    const selectedSubscription = new URLSearchParams(window.location.search).get(
        "product_id"
    )
    const selectedPrice = new URLSearchParams(window.location.search).get(
        "price_id"
    )
    const {user} = useContext(UserContext)
    const {getSubscriptionProducts, createSetupIntent} = useContext(ClientContext)
    const [products, setProducts] = useState(null)
    const [subscribed, setSubscribed] = useState(false)
    const [selected, setSelected] = useState(selectedSubscription || "free")
    const [schedule, setSchedule] = useState("month")
    const [checkout, setCheckout] = useState(false)
    const [price, setPrice] = useState(null)
    const {loadSubscriptions, subscriptions, stripePromise} = useContext(StripeContext)
    const [setupIntent, setSetupIntent] = useState(null)
    const [clientSecret, setClientSecret] = useState(null)
    const [activeSubscription, setActiveSubscription] = useState(null)

    useEffect(() => {
        getSubscriptionProducts().then((products) => {
            setProducts(products)
            if (selectedSubscription) {
                const product = products.data.find(({id}) => id === selectedSubscription)
                setSelected(product)
                if (selectedPrice) {
                    setPrice(product.prices.find(({id}) => id === selectedPrice))
                }    
            }
        })
        setClientSecret(new URLSearchParams(window.location.search).get(
            "setup_intent_client_secret"
        ))
    
        loadSubscriptions()
    }, [])

    useEffect(() => {
        if (subscriptions) {
            const subscription = subscriptions.find(({status}) => status === "active")
            if (subscription) {
                setActiveSubscription(subscription)
                setSubscribed(true)
                setSelected(subscription.stripe.product)
                setPrice(subscription.stripe.price)
            }
        }
    }, [subscriptions])

    useEffect(() => {
        if (user) {
            if(user.roles.includes("subscriber")) {
                setSubscribed(<h2 className="brand">You are subscribed!</h2>)
            }
        }
    }, [user])

    useEffect(() => {
        createSetupIntent().then((setupIntent) => {
            setSetupIntent(setupIntent)
        })
    }, [checkout])



    const selectedClass = "bg-primary text-white"
    return <Container>
        <h1 className="brand">Subscription Options</h1>
        <Form.Check type="switch" id="schedule" label={capitalCase(schedule)} onChange={(e) => {
            setSchedule(e.target.checked ? "year" : "month")
        }} />
        <Row>
            <Col>
                <Card onClick={() => {
                    setSelected("free")
                }} className={selected === "free" && selectedClass}>
                    <Card.Header>
                        <h2 className="brand">Free </h2>{!subscribed && "Current"}
                    </Card.Header>
                    <Card.Body>
                        <ul>
                            <li>2 Rulesets</li>
                            <li>5 Characters / Ruleset</li>
                            <li>5 Campaigns / Ruleset</li>
                            <li>Unlimited Dice Rolls</li>
                        </ul>
                    </Card.Body>
                </Card>
            </Col>
            {products?.data && products.data.sort(({name: a}, {name: b}) => a.localeCompare(b)).map((product) => {
                return <Col key={product.id}>
                    <SubscriptionProduct onClick={() => {
                        setSelected(product)
                        setPrice(product.prices.find(({recurring}) => recurring.interval === schedule))
                    }}
                    selectedClass={selectedClass}
                    selected={selected.id === product.id}
                    product={product}
                    active={subscribed && activeSubscription.stripe_product_id === product.id}
                    schedule={schedule}
                    />
                </Col>
            })}
        </Row>
        <Row>
            <Col className={"d-flex mt-5 align-items-center justify-content-center"}>
                <Button variant="primary" size="lg" disabled={subscribed && selected.id === activeSubscription.stripe_product_id} onClick={() => {
                    setCheckout(true)
                }}>{!subscribed ? "Subscribe" : "Change Subscription"}</Button>
            </Col>
        </Row>
        <Modal show={checkout || (clientSecret && stripePromise)} onHide={() => {
            setCheckout(false)
            setClientSecret(null)
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Subscribe</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Subscribe to {selected?.name} for {decorateCurrency(price?.unit_amount)} / {schedule}</p>
                <Form>
                    <Elements stripe={stripePromise} options={{
                        clientSecret: setupIntent?.client_secret
                    }}>
                        {!clientSecret && <CheckoutForm product_id={selected?.id} price_id={price?.id}/>}
                        {clientSecret && <Subscribed product_id={selected?.id} price_id={price?.id}/>}
                    </Elements>
                </Form>
            </Modal.Body>
        </Modal>
    </Container>
}