import React, { useContext, useState, useEffect, lazy, Suspense } from "react"
import { Breadcrumb, Button, Container, Row, Col, Navbar,InputGroup, Form, DropdownButton, Dropdown } from "react-bootstrap"
import RulesetContext from "services/RulesetContext"
import UserContext from "services/UserContext"
import NoticeContext from "services/NoticeContext"
import ClientContext from "services/ajax"

export default function Characters() {
    const {ruleset} = useContext(RulesetContext)
    const {user} = useContext(UserContext)
    const {addNotice} = useContext(NoticeContext)
    const {getCharacters, deleteCharacter} = useContext(ClientContext)
    const [slots, setSlots] = useState(0)
    const [availableSlots] = useState((user.roles.includes("subscriber") || user.roles.includes("administrator")) ? "Unlimited" : 5)
    const [characters, setCharacters] = useState([])
    const [filterTerm, setFilterTerm] = useState("")

    function refreshCharacters() {
        getCharacters(ruleset).then(characters => {
            setCharacters(characters)
            setSlots(characters.length)
        })
    }

    const CharacterCard = lazy(() => import(`components/${ruleset.id}/CharacterCard`))

    useEffect(() => {
        if (ruleset) {
            refreshCharacters()
        } else {
            addNotice("Error", "Select a ruleset above to view your characters.", "danger")    
        }
    }, [ruleset])


    return <Container>
        <Breadcrumb>
            <Breadcrumb.Item>
                <Navbar.Brand href="/" style={{
                    backgroundImage: "url(/logo512.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    paddingLeft: "20px",
                    color: "#2c99ce",
                    fontSize: "1em"
                }}/></Breadcrumb.Item>
            <Breadcrumb.Item>{ruleset?.name}</Breadcrumb.Item>
            <Breadcrumb.Item>Characters</Breadcrumb.Item>
        </Breadcrumb>
        <Container className={"justify-content-between"}>
            <Row>
                <Col>
                    <h1 className="brand">My Characters</h1>
                </Col>
                <Col className={"d-flex flex-column align-items-end"}>
                    { ruleset?.id && <Button onClick={() => {
                        window.location.assign(`characters/builder/${ruleset.id}`)
                    }}>Create a Character</Button>}
                </Col>
            </Row>
            <hr className={"border-bottom border-primary border-4 opacity-100"}></hr>
            <Row>
                <h3 className="brand">Slots: {slots}/{availableSlots}</h3>
            </Row>
            <Row>
                <Col className={"d-flex flex-row"}>
                    <InputGroup className={"w-75"}>
                        <InputGroup.Text>
                            <i className="bi bi-search"></i>
                        </InputGroup.Text>
                        <Form.Control type={"search"} value={filterTerm} onChange={({target}) => {
                            setFilterTerm(target.value)
                        }}/>
                    </InputGroup>
                    <DropdownButton title="Sort By"  className={"w-25 d-flex flex-column align-items-end"}>
                        <Dropdown.Item>Created: Newest</Dropdown.Item>
                        <Dropdown.Item>Created: Oldest</Dropdown.Item>
                        <Dropdown.Item>Name: A to Z</Dropdown.Item>
                        <Dropdown.Item>Name: Z to A</Dropdown.Item>
                        <Dropdown.Item>Modified: Latest</Dropdown.Item>
                        <Dropdown.Item>Modified: Oldest</Dropdown.Item>
                    </DropdownButton>
                </Col>
            </Row>
        </Container>
        <Container fluid className={"d-flex flex-row flex-wrap justify-content-between"}>
            <Suspense fallback={<div>Loading...</div>}>
                {characters.filter((character) => {
                    if (!filterTerm) {
                        return character
                    }
                    return character?.settings?.name?.toLowerCase().includes(filterTerm?.toLowerCase()) || character?.class?.name?.toLowerCase()?.includes(filterTerm?.toLowerCase()) || character?.traits?.gender?.toLowerCase()?.includes(filterTerm?.toLowerCase())
                }).map((character) => {
                    return <CharacterCard key={`character-${character.id}`} character={character}>
                        <Button className='ms-2' href={`/character/${character.id}`}>View</Button>
                        <Button className='ms-2' href={`/characters/builder/${character.ruleset_id}/standard/${character.id}/0`}><i className='bi-pencil'/></Button>
                        <Button className='ms-2' variant="danger" onClick={() => {
                            deleteCharacter(character).then(refreshCharacters)
                        }}><i className='bi-trash'/></Button>
                    </CharacterCard>
                })}
            </Suspense>
        </Container>
    </Container>
}