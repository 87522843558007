import React, {createContext, useState, useContext} from "react"
import NoticeContext from "./NoticeContext"
import {Dice} from "../components/Dice"

Dice.init().then(() => {
    document.addEventListener("mousedown", () => {
        const diceBoxCanvas = document.getElementById("dice-canvas")
        if (window.getComputedStyle(diceBoxCanvas).display !== "none") {
            Dice.hide().clear()
        }
    })
})

const DiceContext = createContext(null)

export function DiceProvider({children}) {
    const [pendingRoll, setPendingRoll] = useState()
    const [handlers, setHandlers] = useState({})
    const {addNotice} = useContext(NoticeContext)

    Dice.onRollComplete = (results) => {
        if (handlers[pendingRoll]) {
            handlers[pendingRoll](results[0])
        } else {
            console.log("lost roll", pendingRoll, results[0])
        }
    }

    const addHandler = (key, cb) => {
        if (handlers[key]) {
            if (handlers[key] === cb) {
                console.log(`handler already set for dice ${key}`)
            } else {
                handlers[key] = cb
                setHandlers({...handlers})
            }
        } else {
            handlers[key] = cb
            setHandlers({...handlers})
        }
    }
    const rollDice = (key, d) => {
        try {
            setPendingRoll(key)
            Dice.show().roll(d)    
        } catch(e) {
            addNotice("Error", "There was an error with the dice roll. Please try again.", "danger")
        }
    }

    return <DiceContext.Provider value={{
        addHandler, rollDice
    }}>
        {children}
    </DiceContext.Provider>

}

export default DiceContext