import React, { useContext } from "react"
import StepWizard from "components/StepWizard"
import defaultAvatar from "../../assets/avatar.png"
import RulesetContext from "../../services/RulesetContext"
import {Button, Navbar, Nav} from "react-bootstrap"
import UserContext from "../../services/UserContext"
import CharacterSettings from "./CharacterSettings"
import CharacterAttributes from "./CharacterAttributes"
import CharacterClass from "./CharacterClass"
import CharacterSkills from "./CharacterSkills"
import CharacterSpells from "./CharacterSpells"
import CharacterItems, { InventoryCurrencyProvider } from "./CharacterItems"
import CharacterContext from "services/CharacterContext"
import Container from "react-bootstrap/Container"
import CharacterTraits from "./CharacterTraits"
import ClientContext from "services/ajax"

export function newCharacter(user) {
    const newCharacter = {}
    newCharacter.instance = {
        level: 1,
        xp: {
            current: 0,
            next: null
        },
        hp: {
            current: null,
            max: null
        },
    }
    newCharacter.settings = {
        name: `${user.name}'s Character`,
        avatar: defaultAvatar,
        sources: [{
            "title": "Player Core Book: Rules & Magic",
            "publisher": "James Edward Raggi"
        }]
    }
    newCharacter.attributes = {
        strength: null,
        intelligence: null,
        wisdom: null,
        dexterity: null,
        constitution: null,
        charisma: null
    }
    newCharacter.class = {
        name: null,
        description: null,
        special: null,
        skills: [],
        possessions: [],
        source: null
    }
    newCharacter.skills = {
        skillPoints: null,
        skills: [],
        spells: []
    }
    newCharacter.traits = {
        personality: null,
        appearance: null,
        age: null,
        height: null,
        weight: null,
        gender: null
    }
    newCharacter.inventory = {
        items: [],
        gp: null,
        sp: null,
        cp: null,
        encumbered: null
    }
    return newCharacter
}

function StepNavigator() {
    const {character} = useContext(CharacterContext)
    const disabled =  !character?.id
    if (!character) return null
    return <Navbar>
        <Nav>
            <Nav.Item>
                <Nav.Link disabled={disabled} href={`/characters/builder/lotfp/standard/${character.id}/0`}>Settings</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link disabled={disabled} href={`/characters/builder/lotfp/standard/${character.id}/1`}>Attributes</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link disabled={disabled} href={`/characters/builder/lotfp/standard/${character.id}/2`}>Class</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link disabled={disabled} href={`/characters/builder/lotfp/standard/${character.id}/3`}>Traits</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link disabled={disabled} href={`/characters/builder/lotfp/standard/${character.id}/4`}>Spells</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link disabled={disabled} href={`/characters/builder/lotfp/standard/${character.id}/5`}>Skills</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link disabled={disabled} href={`/characters/builder/lotfp/standard/${character.id}/6`}>Inventory</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link disabled={disabled} href={`/character/${character.id}`}>View Sheet</Nav.Link>
            </Nav.Item>
        </Nav>
    </Navbar>
}
export default function CreateCharacter({ system, build }) {
    const {user} = useContext(UserContext)
    const {getRandomCharacter, createCharacter} = useContext(ClientContext)
    const methods = [
        {
            title: "Standard",
            key: "standard",
            description: "Create a character by making choices using a step-by-step approach.",
            onClick: () => {
                const character = newCharacter(user)
                createCharacter(system, character).then((c) => {
                    window.location.assign(`/characters/builder/${system}/standard/${c.id}/0`)
                    return
                })
            }
        },
        {
            title: "Randomize",
            key: "random",
            description: "Create a character of any type with random attributes from Total Party Kill.",
            onClick: async () => {
                const character = await getRandomCharacter(ruleset)
                window.location.assign(`/characters/builder/${system}/random/${character.id}/0`)
            }
        },
    ]

    const { ruleset } = useContext(RulesetContext)
    if (ruleset && ruleset?.id !== system) {
        window.location.assign(`/characters/builder/${ruleset.id}`)
    }

    if (!build) {
        return (
            <Container>
                <div className={"stage-1"}>
                    <h1>Character Creation Method</h1>
                    <p>Choose one of {methods.length} ways to create your character</p>
                    <div className={"d-flex flex-column flex-lg-row"}>
                        {methods.map((method, i) => {
                            return (
                                <Button key={`method-${i}`} className={"m-1"} onClick={method.onClick} >
                                    <h1>{method.title}</h1>
                                    <p>{method.description}</p>
                                </Button>
                            )
                        })}
                    </div>
                </div>
            </Container>
        )
    }
    return <StepWizard 
        system={system} 
        build={build}
        stepNavigator={<StepNavigator/>}
    >
        <CharacterSettings/>
        <CharacterAttributes/>
        <CharacterClass/>
        <CharacterTraits/>
        <InventoryCurrencyProvider><CharacterSpells/></InventoryCurrencyProvider>
        <CharacterSkills/>
        <InventoryCurrencyProvider><CharacterItems/></InventoryCurrencyProvider>
    </StepWizard>
}
