import React, { useContext, lazy, Suspense } from "react"
import { Placeholder } from "react-bootstrap"
import {CharacterContextProvider} from "services/CharacterContext"
import RulesetContext from "services/RulesetContext"

export default function CharacterViewer() {
    const {ruleset} = useContext(RulesetContext)

    const Component = lazy(() => import(`../components/${ruleset.id}/CharacterSheet`))

    return <CharacterContextProvider>
        <Suspense fallback={<Placeholder/>}>
            <Component/>
        </Suspense>
    </CharacterContextProvider>
}