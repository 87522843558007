const {hostname} = window.location
const hostParts = window.location.hostname.split(".")
hostParts.splice(hostParts.indexOf("osrbeyond"), 0, "api")
let API_HOST = `https://${hostParts.join(".")}`
if (hostname === "localhost") {
    API_HOST = "http://localhost:4001"
}

const shortHosts = {
    "localhost": "http://localhost:3000",
    "osrbeyond.com": "https://osrb.in",
    "staging.osrbeyond.com": "https://staging.osrb.in",
    "dev.osrbeyond.com": "https://dev.osrb.in",
}



export const SHORT_HOST = shortHosts[hostname]

export default API_HOST