import React,{ useContext } from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useAccordionButton } from "react-bootstrap/AccordionButton"
import Accordion from "react-bootstrap/Accordion"
import {UserAvatar, UserControlContainer} from "components/Avatar"
import RuleSetSelector from "components/RuleSetSelector"
import UserContext from "services/UserContext"
import Collections from "components/Collections"

function CustomToggle({children, eventKey}) {
    const decoratedOnClick = useAccordionButton(eventKey)
    const decoratedHide = useAccordionButton("none")
    return (<a 
        role="button" 
        className="nav-link" 
        href={`${eventKey}`} 
        onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
        }}
        onMouseEnter={() => {
            decoratedOnClick()
        }}
        onTouchStart={() => {
            decoratedOnClick()
        }}
        onMouseLeave={decoratedHide}
    >{children}</a>)
}

export default function Header() {
    const {user} = useContext(UserContext)
    return <Container fluid className={"p-0 bg-black"}>
        <Row className="border-bottom border-primary border-3 mx-0">
            <Col className={"text-center"}>
                <Container className={"mx-auto"}>
                    <Navbar bg="black" variant="dark" style={{
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundBlendMode: "hard-light"
                    }} expand="lg">
                        <Container>
                            <Row>
                                <Col xs={12} md={2} className={"d-flex align-items-center justify-content-center"}>
                                    <Navbar.Brand href="/" className={"d-block"} style={{
                                        width: "5rem",
                                        height: "5rem",
                                        backgroundImage: "url(/logo512.png)",
                                        backgroundSize: "contain",
                                        backgroundRepeat: "no-repeat",
                                        color: "#2c99ce",
                                    }}></Navbar.Brand>
                                </Col>
                                <Col xs={12} md={2}>
                                    <span className={"brand text-primary fs-1"}>Beyond</span>
                                </Col>
                                <Col xs={12} md={7}>
                                    <RuleSetSelector/>
                                </Col>
                            </Row>
                        </Container>

                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse className={"justify-content-end"} id="basic-navbar-nav">
                            <Nav>
                                {user && <UserControlContainer user={user}><UserAvatar user={user}/><span className={"text-white"}>{user.name}</span></UserControlContainer>}
                                {!user && <div className={"d-flex flex-row"}><Nav.Link href="/register">Sign Up</Nav.Link><a className="nav-link">|</a> <Nav.Link href="/login">Sign In</Nav.Link></div>}
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
            </Col>
        </Row>
        <Accordion defaultActiveKey={"0"} className="text-center w-100 bg-dark">
            <Container className={"mx-auto"}>
                <Row>
                    <Col>
                        <Navbar bg="dark" variant="dark" expand="lg">
                            <Navbar.Toggle aria-controls='users-navbar'/>
                            <Navbar.Collapse id='users-navbar' className='mx-auto text-light'>
                                <CustomToggle as={"button"} onClick={(e) => {e.stopPropagation(); e.preventDefault()}} eventKey={"collections"}>
                                    Collections <i className="bi bi-caret-down-fill"></i>
                                </CustomToggle>
                                <Nav>
                                    {/* <Nav.Link>Game Rules <i className="bi bi-caret-down-fill"></i></Nav.Link>
                                    <Nav.Link>Sources <i className="bi bi-caret-down-fill"></i></Nav.Link>
                                    <Nav.Link>Tools <i className="bi bi-caret-down-fill"></i></Nav.Link>
                                    <Nav.Link>Marketplace <i className="bi bi-caret-down-fill"></i></Nav.Link>
                                    <Nav.Link>Media <i className="bi bi-caret-down-fill"></i></Nav.Link>
                                    <Nav.Link>Forums</Nav.Link>
                                    <Nav.Link>New Player Guide</Nav.Link> */}
                                    <Nav.Link href="/subscribe">Subscribe</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </Col>
                </Row>
            </Container>
            <Collections/>
        </Accordion>
    </Container>
}