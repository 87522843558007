import React, {useContext, useEffect, useState} from "react"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Container from "react-bootstrap/Container"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import { Row, Col } from "react-bootstrap"
import NoticeContext from "services/NoticeContext"
import ClientContext from "services/ajax"
import whoweare from "../assets/whoweare.png"

function SignUpForm({name, email, setName, setEmail, onError}) {
    const { registerUser } = useContext(ClientContext)
    const [sent, setSent] = useState()
    const [agreed, setAgreed] = useState(false)
    const [loading, setLoading] = useState(false)

    return <Container>
        <div className="vstack gap-3 lonely">
            <h1 className={"brand"}>Sign Up:</h1>
            {sent && <h1>a Registration Email has been sent to your address with further instructions. If you do not receive it within 5 minutes go to <a href="/forgot-password">Forgot Password</a></h1>}
            {!sent && <>
                <Form.Group>
                    <Form.Label>Name:</Form.Label>
                    <Form.Control type='text' onChange={({target}) => setName(target.value)}></Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Email:</Form.Label>
                    <Form.Control type="email" onChange={({target}) => setEmail(target.value)}></Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Check className={"d-inline-block"} onChange={({target}) => {setAgreed(target.checked)}}></Form.Check> <Form.Text className={"d-inline-block"}>I agree to the <a href="/tos">terms and conditions</a> of OSRBeyond.com</Form.Text>
                </Form.Group>
                <div className="hstack gap-2">
                    <Button disabled={(!(name && email)) || loading} onClick={() => {
                        if (!agreed) {
                            return onError("You must agree to the terms of service to register.")
                        }
                        setLoading(true)
                        registerUser(email, name).then(({status, message}) => {
                            if (status === "error") {return onError(message)}
                            setSent(true)
                            setLoading(false)
                        })
                    }}>Register</Button> 
                </div>      
            </>}
        </div>
    </Container>
}

function SignInForm({email, password, setEmail, forwardTo, setPassword, onError}) {
    const { loginUser } = useContext(ClientContext)
    const [loading, setLoading] = useState(false)
    return <Container>
        <div className="vstack gap-3 lonely">
            <h1 className={"brand"}>Sign In:</h1>
            <Form.Group>
                <Form.Label>Email:</Form.Label>
                <Form.Control type="email" onChange={({target}) => setEmail(target.value)}></Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>Password:</Form.Label>
                <Form.Control type="password" onChange={({target}) => setPassword(target.value)}></Form.Control>
            </Form.Group>
            <div className="hstack gap-2">
                <Button disabled={(!(email && password)) || loading} onClick={() => {
                    setLoading(true)
                    loginUser(email, password).then(({status, message, user}) => {
                        if (status === "error") { return onError(message) }
                        if (user.token) {
                            sessionStorage.setItem("user", JSON.stringify(user))
                            window.location.assign(forwardTo)
                        }
                        setLoading(false)
                    })
                }}>Login</Button> 
                <Button variant="danger" href={"/forgot-password"}>Forgot Password</Button>
            </div>
        </div>
    </Container>

}

export default function Register() {
    let path = window.location.pathname
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [forwardTo, setForwardTo] = useState("/")
    const [handler, setHandler] = useState(path)
    const {addNotice} = useContext(NoticeContext)

    const onError = (message) => {
        addNotice("Error", message, "danger")
    }

    useEffect(() => {
        if (path !== "/register" && path !== "/login") {
            setForwardTo(path)
            setHandler("/login")
        }
    }, [path])
    return <Container fluid>
        <Container fluid className={"p-0"}>
            <Row>
                <Col style={{
                    backgroundImage: `url(${whoweare})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    height: "100vh"
                }}>
                
                </Col>
                <Col className="d-flex flex-column">
                    <Row className={"h-25"}>
                    </Row>
                    <Row>
                        <Tabs id="user-tabs" activeKey={handler} className="mb-3" onSelect={(k) => {window.location.assign(k)}}>
                            <Tab eventKey='/register' title="Sign Up">
                                <SignUpForm email={email} name={name} setEmail={setEmail} forwardTo={forwardTo} setName={setName} onError={onError}/>
                            </Tab>
                            <Tab eventKey='/login' title="Sign In">
                                <SignInForm email={email} password={password} setEmail={setEmail} forwardTo={forwardTo} setPassword={setPassword} onError={onError}/>
                            </Tab>
                        </Tabs>                        
                    </Row>
                </Col>
            </Row>  

        </Container>
    </Container>
}