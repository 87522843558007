import { UserAvatar } from "components/Avatar"
import React, { useEffect, useState, useContext, lazy, Suspense } from "react"
import { Container, Row, Col, Button, Card } from "react-bootstrap"
import { useParams } from "react-router-dom"
import ClientContext from "services/ajax"
import { SHORT_HOST } from "services/api"
import RulesetContext from "services/RulesetContext"
import campaign_cover from "../assets/campaign-cover.png"

export default function CampaignViewer() {
    const { slug } = useParams()
    const { getCampaign, removeFromCampaign } = useContext(ClientContext)
    const {ruleset} = useContext(RulesetContext)
    const [campaign, setCampaign] = useState()
    const [copyLabel, setCopyLabel] = useState("Copy Link")
    // eslint-disable-next-line no-undef
    const shortUrlHost = SHORT_HOST
    const inviteLink = `${shortUrlHost}/campaign/join/${campaign?.slug}`

    const CharacterCard = lazy(() => import(`components/${ruleset.id}/CharacterCard`))

    useEffect(() => {
        getCampaign({slug}).then((campaign) => {
            setCampaign(campaign)
        })
    }, [])

    return <Container className={"mb-5"}>
        <Row>
            <div className={"d-block w-100"} style={{height: 150, backgroundImage: `url(${campaign?.cover || campaign_cover})`, backgroundSize: "cover", backgroundPosition: "center"}}/>
            <h1 className={"brand"}>{campaign?.name}</h1>
            <hr className={"border-bottom border-primary border-4 opacity-100"}></hr>
        </Row>
        <Row className={"flex-sm-row-reverse"}>
            <Col>
                <Card className={"border-0"}>
                    <Card.Header className={"border text-center rounded-1 border-radius-3 border-primary border-dashed"}>
                        <strong className={"text-primary"}>{inviteLink}</strong>
                        <p>Send players this invite link to have them join your campaign.</p>
                    </Card.Header>
                    <div className="text-primary d-flex justify-content-between">
                        <a onClick={() => {
                            navigator.clipboard.writeText(inviteLink)
                            setCopyLabel("Copied")
                        }}>{copyLabel}</a>
                        <a onClick={() => {console.log("reset invite link")}}>Reset Invite Link</a>
                    </div>
                </Card>
            </Col>
            <Col>
                <Button ><i className="bi-chat"/> Game Log</Button>
                <p dangerouslySetInnerHTML={{__html: campaign?.description}}></p>
            </Col>
        </Row>
        <hr className={"border-bottom border-primary border-1 opacity-100"}></hr>
        <Row className={"w-50"}>
            <Col sm={3}>
                <UserAvatar user={campaign?.owner} xl/>
            </Col>
            <Col sm={8}>
                <h3>{campaign?.owner?.name}</h3>
                <p>Game Master</p>
            </Col>
        </Row>
        <Row>
            <h3>Active Characters</h3>
            <p>These characters are members of your campaign.</p>
            <Container fluid className={"d-flex flex-row"}>
                <Suspense fallback={<div>Loading...</div>}>
                    { campaign?.characters?.map((character) => {
                        return <CharacterCard key={`character-${character.id}`} character={character}>
                            <Button className='ms-2' href={`/character/${character.id}`}>View</Button>
                            <Button className='ms-2' variant="danger" onClick={() => {
                                removeFromCampaign(character)
                            }}>Remove</Button>
                        </CharacterCard>
                    })}
                </Suspense>
            </Container>

        </Row>
    </Container>
}