
import React, { useContext, useState, useEffect, createContext, useMemo } from "react"
import CharacterContext from "services/CharacterContext"
import {Container, ListGroup, Row, Col, Button, Form, Accordion, InputGroup, Modal} from "react-bootstrap"
import DiceRoller from "components/DiceRoller"
import chaching from "../../assets/chaching.mp3"
import {v4} from "uuid"
import NoticeContext from "services/NoticeContext"
import RulesetContext from "services/RulesetContext"

export const CurrencyContext = createContext(null)

export const EncumberanceContext = createContext(null)

export function EncumberanceProvider({children}) {
    const {items} = useContext(InventoryContext)
    const {gp, sp, cp} = useContext(CurrencyContext)
    const [encumberance, setEncumberance] = useState({})

    useEffect(() => {
        const mounts = items.filter(({category}) => ["Animals", "Vehicles"].includes(category))
        const containers = items.filter(({category}) => category === "Containers")
        const coins = Math.floor((gp + sp + cp) / 100)
        const _items = items.slice().filter(({container}) => {
            if (!container) return true
            const containerContainer = containers.find(({id}) => id === container)?.container
            const mountIds = mounts.map(({id}) => id)
            !mountIds.includes(container) || !mountIds.includes(containerContainer)
        })
        const overSized = _items.filter(({encumberance}) => encumberance === "Oversized").length
        const chainIndex = _items.findIndex(({type, equipped}) => type === "Chain Armor" && equipped)
        let armor = 0
        const plateIndex = _items.findIndex(({type, equipped}) => type === "Plate Armor" && equipped)
        if (chainIndex > -1) {
            _items.splice(_items.findIndex(({type}) => type === "Chain Armor"), 1)
            armor += 1
        }
        if (plateIndex > -1) {
            _items.splice(_items.findIndex(({type}) => type === "Plate Armor"), 1)
            armor += 2
        }
        const normal = _items.filter(({encumberance, category, equipped}) => {
            return encumberance === "Normal" && !(category === "Containers" && equipped)
        }).reduce((acc, item) => acc + (item.qty || 1), 0)
        const none = _items.filter(({encumberance}) => encumberance === "None").length
        const itemCount = normal + none
        setEncumberance({itemCount, armor, coins, overSized, total: (Math.floor((itemCount - 1) / 5)) + armor + coins + overSized})
    }, [items, gp, sp, cp])

    const {itemCount, armor, coins, overSized, total} = encumberance

    return <EncumberanceContext.Provider value={encumberance}>
        <div>Encumberance {total} | Coin {coins} | Items {itemCount} | Armor {armor} | Oversized {overSized}</div>
        {children}
    </EncumberanceContext.Provider>
}

export function CurrencyContextProvider(props) {
    const {character, updateCharacter} = useContext(CharacterContext)
    const [gp, setGp] = useState(character?.inventory?.gp)
    const [sp, setSp] = useState(character?.inventory?.sp)
    const [cp, setCp] = useState(character?.inventory?.cp)

    const reduceCurrency = (gp = 0, sp = 0, cp = 0) => {
        let _gp = gp
        let _sp = sp
        let _cp = cp
        if (_cp > 10) {
            _sp += Math.floor(_cp / 10)
            _cp -= 10 * Math.floor(_cp / 10)
        }
        if (_sp > 50) {
            _gp += Math.floor(_sp / 50)
            _sp -= 50 * Math.floor(_sp / 50)
        }
        return {gp: parseInt(_gp || 0, 10), sp: parseInt(_sp || 0, 10), cp: parseInt(_cp || 0, 10)}
    }

    useEffect(() => {
        if (character?.inventory) {
            setGp(character.inventory.gp)
            setSp(character.inventory.sp)
            setCp(character.inventory.cp)
        }
    }, [character])

    useEffect(() => {
        if (!isNaN(gp) || !isNaN(sp) || !isNaN(cp)) {
            updateCharacter(character, "inventory", {gp, sp, cp})
        }
    }, [gp, sp, cp])

    async function spendCurrency(costStr) {
        return new Promise((resolve, reject) => {
            const cost = parseInt(costStr.replace(">", ""), 10)
            const denom = costStr.slice(-2)
            let asCp = 0
            if (denom === "gp") {
                asCp = cost * 500
            } else if (denom === "sp") {
                asCp = cost * 10
            } else if (denom === "cp") {
                asCp = cost
            }
            const canSpend = gp * 500 + sp * 10 + cp
            if (canSpend - asCp < 0) {
                reject("Not enough money!")
            } else {   
                resolve(reduceCurrency(0, 0, (canSpend - asCp || 0)))
            }
        })
    }

    const value = useMemo(() => {
        return {gp, sp, cp, spendCurrency, setCp, setSp, setGp}
    }, [gp, sp, cp])

    return <CurrencyContext.Provider value={value} {...props}></CurrencyContext.Provider>
}

export const InventoryContext = createContext(null)

InventoryContextProvider.label = "Inventory"

export function InventoryContextProvider(props) {
    const {character, addItem, deleteItem, updateCharacter, updateItem} = useContext(CharacterContext)
    const {spendCurrency} = useContext(CurrencyContext)
    const {sources} = useContext(RulesetContext)
    const [items, setItems] = useState(character?.inventory?.items || [])
    const [categories, setCategories] = useState([])
    const [market, setMarket] = useState(null)
    const [marketType, setMarketType] = useState("city")

    useEffect(() => {
        if (character?.inventory?.items) {
            setItems(character.inventory.items)
        }
        if (sources) {
            const _categories = new Set()
            const _market = sources.items.reduce((acc, item) => {
                _categories.add(item.category)
                acc[item.category] = acc[item.category] || []
                acc[item.category].push(item)
                return acc
            }, {})
            setMarket(_market)
            setCategories(_categories)
        }
    }, [sources, character])

    const itemsByContainer = useMemo(() => {
        const vehicles = items.filter(({category}) => ["Vehicles"].includes(category))
        const animals = items.filter(({category}) => ["Animals"].includes(category))
        const containers = items.filter(({category}) => ["Containers"].includes(category))
        const rest = items.filter(({category}) => !["Containers", "Vehicles", "Animals"].includes(category))
        const _items = [...vehicles, ...animals, ...containers, ...rest]
        return _items.reduce((acc, item) => {
            if (item.category === "Containers") {
                acc[`${item.id}`] = acc[item.id] || {contents: {}, type: item.type}
            }
            if (item.category === "Vehicles") {
                acc[`${item.id}`] = acc[item.id] || {contents: {}, type: item.type}
            }
            if (item.category === "Animals") {
                acc[`${item.id}`] = acc[item.id] || {contents: {}, type: item.type}
            }
            if (item.container) {
                acc[item.container].contents[item.id] = item
            } else {
                acc.inventory.contents[item.id] = item
            }
            return acc
        }, {inventory: {type: "Inventory", contents: {}}})
    }, [items])

    async function purchaseItem(item) {
        const _items = [...items]
        const cost = item.cost[marketType]
        const {gp, sp, cp } = await spendCurrency(cost)
        let inItemIndex
        if (item.id) {
            inItemIndex = _items.findIndex(({id}) => id === item.id)
        } else {
            inItemIndex = _items.findIndex(({type}) => type === item.type)
        }
        if (!["Containers", "Vehicles", "Animals"].includes(item.category)) {
            if (inItemIndex !== -1) {
                const inItem = {..._items[inItemIndex]}
                inItem.qty = inItem.qty || 1
                inItem.qty += 1
                _items.splice(inItemIndex, 1, inItem)
            } else {
                _items.unshift({...item, id: v4()})
            }    
        } else {
            _items.unshift({...item, id: v4()})
        }
        return updateCharacter(character, "inventory", {gp, sp, cp, items: _items.filter(({id}) => id)})
    }

    function _addItem(item) {
        if (!["Containers", "Vehicles", "Animals"].includes(item.category) && item.id) {
            updateItem(character, item.id, {qty: (item.qty || 1) + 1}).then((item) => {
                items.splice(items.findIndex(({id}) => id === item.id), 1, item)
                setItems([...items])
            })
        } else {
            return addItem(character, {...item, id: v4(), qty: 1}).then((item) => {
                items.unshift(item)
                setItems([...items])
            })
        }
    }

    function removeItem(item) {
        if (item.qty > 1) {
            return updateItem(character, item.id, {qty: item.qty - 1}).then((item) => {
                items.splice(items.findIndex(({id}) => id === item.id), 1, item)
                setItems([...items])
            })
        } else {
            return deleteItem(character, item.id).then(() => {
                items.splice(items.findIndex(({id}) => id === item.id), 1)
                setItems([...items])
            })
        }
    }

    function stowItem(item, container) {
        return updateItem(character, item.id, {container: container?.id || null}).then((item) => {
            items.splice(items.findIndex(({id}) => id === item.id), 1, item)
            setItems([...items])
        })
    }

    function equipItem(item, equipped) {
        return updateItem(character, item.id, {equipped}).then((item) => {
            items.splice(items.findIndex(({id}) => id === item.id), 1, item)
            setItems([...items])
        })
    }

    function saveItem(item) {
        return updateItem(character, item.id, item).then((item) => {
            items.splice(items.findIndex(({id}) => id === item.id), 1, item)
            setItems([...items])
        })
    }

    const value = useMemo(() => {
        return {
            categories,
            items,
            addItem: _addItem,
            purchaseItem,
            removeItem,
            equipItem,
            stowItem,
            setItems,
            saveItem,
            itemsByContainer,
            market,
            marketType,
            setMarketType                
        }
    }, [items, market, marketType])

    return <InventoryContext.Provider value={value} {...props}></InventoryContext.Provider>
}

export function InventoryCurrencyProvider({children}) {
    return <CurrencyContextProvider>
        <InventoryContextProvider>
            {children}
        </InventoryContextProvider>
    </CurrencyContextProvider>
}

export function ItemTemplate({item, context, onBuy}) {
    return <ListGroup.Item className={(item?.cost?.[context] || !context) ? "" : "d-none"}>
        <Row>
            <Col xs={4}>{item?.name ? `${item.name} (${item.type})` : item?.type}</Col>
            <Col xs={4}>{context ? item?.cost?.[context] : item?.ac ? `AC ${item?.ac}` : item?.damage ? `Dmg ${item?.damage}` : ""}</Col>
            <Col xs={2}>{item?.encumberance}</Col>
            {!context && <Col xs={2}>{item?.qty || 1}</Col>}
            {context && <Col xs={2}><Button className={"btn-sm"} onClick={onBuy}>Buy <i className="bi-plus"/></Button></Col>}
        </Row>
    </ListGroup.Item>
}

export function StowModal({containers, show, onHide, onSelect}) {
    return <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>Stow Item</Modal.Header>
        <Modal.Body>
            {containers.map((container) => {
                return <Button key={container.id} onClick={() => onSelect(container)}>{container.type}</Button>
            })}
        </Modal.Body>
    </Modal>
}

export function EditItemModal({item, show, onHide}) {
    const {categories, saveItem, addItem} = useContext(InventoryContext)
    const [name, setName] = useState(item?.name || "")
    const [type, setType] = useState(item?.type || "")
    const [category, setCategory] = useState(item?.category || "")
    const [cost, setCost] = useState(item?.cost || {})
    const [ac, setAc] = useState(item?.ac || "")
    const [damage, setDamage] = useState(item?.damage || "")
    const [encumberance, setEncumberance] = useState(item?.encumberance || "")

    useEffect(() => {
        setName(item?.name)
        setType(item?.type)
        setCategory(item?.category)
        setCost(item?.cost)
        setAc(item?.ac)
        setDamage(item?.damage)
        setEncumberance(item?.encumberance)
    }, [item])

    return <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Edit Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Type</Form.Label>
                    <Form.Control type="text" value={type} onChange={(e) => setType(e.target.value)}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <Form.Control as="select" value={category} onChange={(e) => setCategory(e.target.value)}>
                        <option value={""}>None</option>
                        {Array.from(categories).map((category) => {
                            return <option key={category} value={category}>{category}</option>
                        })}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Rural Cost</Form.Label>
                    <Form.Control className={"w-25"} type="text" value={cost?.["rural"]} onChange={({target}) => setCost({...cost, rural: target.value})}/>
                    <Form.Label>City Cost</Form.Label>
                    <Form.Control className={"w-25"} type="text" value={cost?.["city"]} onChange={({target}) => setCost({...cost, city: target.value})}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>AC</Form.Label>
                    <Form.Control type="text" value={ac} onChange={(e) => setAc(e.target.value)}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Damage</Form.Label>
                    <Form.Control type="text" value={damage} onChange={(e) => setDamage(e.target.value)}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Encumberance</Form.Label>
                    <Form.Control as="select" value={encumberance} onChange={(e) => setEncumberance(e.target.value)}>
                        <option value={"None"}>None</option>
                        <option value={"Normal"}>Normal</option>
                        <option value={"Oversized"}>Oversized</option>
                    </Form.Control>
                </Form.Group>
                <Button onClick={() => {
                    if (item?.id) {
                        saveItem({...item, name, type, category, cost, ac, damage, encumberance}).then(onHide)
                    } else {
                        addItem({name, type, category, cost, ac, damage, encumberance}).then(onHide)
                    }
                }}>Save</Button>
            </Form>
        </Modal.Body>
    </Modal>

}

export function CharacterInventory() {
    const {items, removeItem, equipItem, stowItem, addItem, itemsByContainer} = useContext(InventoryContext)
    const {gp, setGp, sp, setSp, cp, setCp} = useContext(CurrencyContext)
    const [show, setShow] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showCurrency, setShowCurrency] = useState(false)
    const [toStow, setToStow] = useState(null)
    const [currentItem, setCurrentItem] = useState(null)
    const [showItemModal, setShowItemModal] = useState(false)

    return <>
        <Row className="text-start mt-2">
            <Col xs={12}><h3 className="brand">Currency</h3></Col>
            <Col xs={3}><h3 className="brand">{gp || 0} gp</h3></Col>
            <Col xs={3}><h3 className="brand">{sp || 0} sp</h3></Col>
            <Col xs={3}><h3 className="brand">{cp || 0} cp</h3></Col>
            <Col xs={3}><Button onClick={() => setShowCurrency(true)}>Edit</Button></Col>
        </Row>
        <hr className="border border-primary border-1"></hr>
        <Modal show={showCurrency} onHide={() => setShowCurrency(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Update Currency</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Gold</Form.Label>
                        <Form.Control type="number" value={gp} onChange={(e) => setGp(parseInt(e.target.value, 10))}></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Silver</Form.Label>
                        <Form.Control type="number" value={sp} onChange={(e) => setSp(parseInt(e.target.value, 10))}></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Copper</Form.Label>
                        <Form.Control type="number" value={cp} onChange={(e) => setCp(parseInt(e.target.value, 10))}></Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowCurrency(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        <StowModal show={show} onHide={() => setShow(false)} containers={(items||[]).filter(({category}) => {
            return ["Containers", "Vehicles", "Animals"].includes(category)
        })} onSelect={(container) => {
            stowItem(toStow, container)
            setToStow(null)
            setShow(false)
        }}></StowModal>
        <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Manage Inventory</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CharacterItems/>
            </Modal.Body>
        </Modal>
        {itemsByContainer && Object.entries(itemsByContainer).map(([key, value]) => {
            return <Container key={key}>
                <h4 className={"brand"}>{value.type}</h4> 
                {value.type === "Inventory" && <><a onClick={() => {setShowModal(true)}} href="#manage-inventory">Manage Inventory</a> <Button onClick={() => {setShowItemModal(true)}}>Add Custom Item</Button></>}
                <ListGroup>
                    <ListGroup.Item className="border-bottom border-bottom-1 border-primary">
                        <Row>
                            <Col xs={1} className={"text-overflow-ellipses"}><h6 className="brand">Equip</h6></Col>
                            <Col xs={4}><h6 className="brand">Item</h6></Col>
                            <Col xs={1}><h6 className="brand">Quantity</h6></Col>
                            <Col xs={1}><h6 className="brand">Encumberance</h6></Col>
                            <Col xs={3}><h6 className="brand">Action</h6></Col>                    
                        </Row>
                    </ListGroup.Item>
                    {Object.entries(value.contents).map(([id, item]) => {
                        return <ListGroup.Item key={id}>
                            <Row key={item.type}>
                                <Col xs={1}>{["Melee Weapon", "Missile Weapon","Armor","Containers"].includes(item.category) && <Form.Check type="checkbox" checked={item.equipped || false} onChange={() => {
                                    equipItem(item, !item.equipped)
                                }}></Form.Check>}</Col>
                                <Col xs={4}>{item?.name ? `${item.name} (${item.type})` : item?.type}</Col>
                                <Col xs={1}>{item.qty || 1}</Col>
                                <Col xs={1}>{item.encumberance}</Col>
                                <Col xs={3}>
                                    {!item.container && <Button variant="primary" onClick={() => {
                                        setToStow(item)
                                        setShow(true)
                                    } }>
                                        <i className={"bi-bag-plus"} />
                                    </Button>}
                                    {item.container && <Button variant="primary" onClick={() => {
                                        stowItem(item, null)
                                    }}><i className="bi-bag-dash"/></Button>}
                                    <Button variant="warning" onClick={() => { 
                                        setCurrentItem(item)
                                        setShowItemModal(true)
                                    }}><i className={"bi-pen"} /></Button>
                                    <Button variant="danger" onClick={() => { 
                                        removeItem(item)
                                    }}><i className={"bi-dash"} /></Button>
                                    <Button variant="success" onClick={() => {
                                        addItem(item)
                                    }}><i className="bi-plus"/></Button>
                                </Col>
                            </Row>
                        </ListGroup.Item>})}
                </ListGroup>    
                <EditItemModal item={currentItem} show={showItemModal} onHide={() => setShowItemModal(false)}/>
            </Container>
        })}
    </>
}

export default function CharacterItems() {
    const {items, market, marketType, setMarketType, purchaseItem} = useContext(InventoryContext)
    const {gp, sp, cp, spendCurrency, setCp, setSp, setGp} = useContext(CurrencyContext)
    const {addNotice} = useContext(NoticeContext)

    function chaChingSound() {
        const snd1  = new Audio()
        const src1  = document.createElement("source")
        src1.type = "audio/mpeg"
        src1.src  = chaching
        snd1.appendChild(src1)
        snd1.play()
        setTimeout(() => {
            src1.remove()
        }, 1000)
    }

    return <Container>
        <h1 className="brand">Inventory</h1>
        <DiceRoller label='Roll Starting SP' d='3d6' button value={sp} keys={["sp"]} onChange={({target}) => {
            setSp(parseInt(target.value, 10))
        }} onResult={(result) => {
            setSp(result.value * 10)
        }} />
        <InputGroup>
            <InputGroup.Text className={"w-50"}>Gold Pieces:</InputGroup.Text>
            <Form.Control value={gp || 0} onChange={({target}) => { setGp(parseInt(target.value, 10)) }} type="number"></Form.Control>
        </InputGroup>
        <InputGroup>
            <InputGroup.Text className={"w-50"}>Silver Pieces:</InputGroup.Text>
            <Form.Control value={sp || 0} onChange={({target}) => { setSp(parseInt(target.value, 10)) }} type="number"></Form.Control>
        </InputGroup>
        <InputGroup>
            <InputGroup.Text className={"w-50"}>Copper Pieces:</InputGroup.Text>
            <Form.Control value={cp || 0} onChange={({target}) => { setCp(parseInt(target.value, 10)) }} type="number"></Form.Control>
        </InputGroup>
        <ListGroup>
            <ListGroup.Item>
                <Row>
                    <Col xs={4}>Item</Col>
                    <Col xs={4}>AC/Dmg</Col>
                    <Col xs={2}>Enc</Col>
                    <Col xs={2}>Qty</Col>
                </Row>
            </ListGroup.Item>
            {items?.map((item, i) => {
                return <ItemTemplate key={`items-${i}`} item={item} />
            })}
        </ListGroup>
        <Form.Label>City / Rural</Form.Label>
        <Form.Check type="switch" id="custom-switch" checked={marketType === "city"} onChange={() => {
            setMarketType(marketType === "city" ? "rural" : "city")
        }} />
        <Accordion>
            {Object.entries((market || {})).map(([category, categoryItems]) => {
                return <Accordion.Item eventKey={category} key={category}>
                    <Accordion.Header>{category}</Accordion.Header>
                    <Accordion.Body>
                        <ListGroup>
                            {categoryItems.map((item, i) => {
                                return <ItemTemplate key={`available-${i}`} item={item} context={marketType} onBuy={() => {
                                    spendCurrency(item.cost[marketType]).then(() => {
                                        chaChingSound()
                                        purchaseItem(item)
                                    }, () => {
                                        addNotice("Insufficient funds", `${item.type} costs ${item.cost[marketType]}, but you only have ${gp}gp ${sp}sp ${cp}cp`, "danger")
                                    })
                                }}/>
                            })}
                        </ListGroup> 
                    </Accordion.Body>
                </Accordion.Item>
            })}
        </Accordion>
    </Container>
}