import React, {useContext} from "react"
import UserContext from "services/UserContext"

const RouteGuard = ({component: Component, level = "", ...rest }) => {
    const {user} = useContext(UserContext)
    const meetsLevel = user?.roles?.includes(level) || level === ""
    if (user?.token && meetsLevel) {
        return <Component {...rest} />
    } else {
        setTimeout(() => {
            window.location.assign("/login")
        }, 500)
        return <div>Unauthorized</div>
    }
}

export default RouteGuard