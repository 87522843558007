import React, { useContext, useEffect, useState } from "react"
import { InputGroup, Form, Button } from "react-bootstrap"
import CharacterContext from "services/CharacterContext"
import DiceContext from "services/DiceContext"
import Beyond20Context from "services/Beyond20Context"
import { signedPrefix } from "services/util"

function translateAttackRoll(result, d) {
    const parts = d.split("+")
    return {
        "critical-failure": false,
        "critical-limit": 20,
        "critical-success": false,
        "discarded": false,
        "fail-limit": null,
        "formula": d,
        "parts": [{
            amount: result.qty,
            faces: parseInt(result.sides.replace("d", ""), 10),
            formula: parts[0],
            modifiers: "",
            rolls: [...result.rolls.map(({value}) => ({roll: value}))]
        }, "+", parseInt(parts[1] || 0, 10)],
        "total": result.value,
        "type": "to-hit"
    }
}

function translateDamageRoll(result, d) {
    const parts = d.split("+")
    const [amount, faces] = parts[0].split("d").map((v) => parseInt(v, 10))
    return ["Physical Damage", {
        formula: d,
        amount: amount,
        faces: faces,
        modifiers: parts[1],
        parts: [{
            roll: result.value
        }],
        total: result.value
    }, 1]
}

export function AttackDice({label, item, d, modifier=0, action, onResult, children}) {
    const {character, convertCharacter} = useContext(CharacterContext)
    const {addHandler, rollDice} = useContext(DiceContext)
    const {b20Settings} = useContext(Beyond20Context)

    const key = `${item.id}-${action}`
    useEffect(() => {
        addHandler(key, (result) => {
            if (b20Settings) {
                const custom = new CustomEvent("Beyond20_rendered-roll", {detail: [{
                    action: "rendered-roll",
                    title: item.type,
                    character: character.settings.name,
                    attack_rolls: action === "attack" ? [translateAttackRoll(result, d, item)] : [],
                    damage_rolls: action === "damage" ? [translateDamageRoll(result, d)] : [],
                    play_sound: true,
                    open: false,
                    request: {
                        action : "roll",
                        type: "attack",
                        advantage: 0,
                        "attack-source": "item",
                        "attack-type": item.category === "Melee Weapon" ? "Melee" : "Range",
                        character: action === "attack" ? convertCharacter(character, b20Settings) : character.settings.name,
                        "damage-types": ["Physical"],
                        "damages": [item.damage],
                        name: item.type,
                        rollAttack: true
                    },
                    total_damages: []
                }]})
                document.dispatchEvent(custom)    
            }
            onResult(result)
        })
    }, [b20Settings])

    return <Button onClick={() => {
        rollDice(key, `${d}${modifier !== 0 ? signedPrefix(modifier) : ""}`)
    }}>{children || label || "Roll"}</Button>

}

export default function DiceRoller({label, form, button, d, min, max, keys, value, sectionKey, onResult, children}) {
    const {character, updateCharacter} = useContext(CharacterContext)
    const {addHandler, rollDice} = useContext(DiceContext)
    const [val, setVal] = useState(value || character?.[sectionKey]?.[keys?.[0]])
    useEffect(() => {
        if (form && !onResult) {
            addHandler(keys, (value) => {
                setVal(value)
                const section = character?.[sectionKey]
                keys.forEach((key) => {
                    section[key] = value
                })
                updateCharacter(character, sectionKey, section)
            })
        } else {
            addHandler(keys, (result) => {
                setVal(result.value)
                onResult(result)
            })
        }

    }, [])

    if (value !== val) {
        setVal(value)
    }

    if (button) {
        return <Button onClick={() => {
            rollDice(keys, d)
        }}>{children || label || "Roll"}</Button>
    }
    return <InputGroup>
        {!button && <InputGroup.Text className={"w-50"}>{label}</InputGroup.Text>}
        {form && <Form.Control value={val} onChange={({target}) => { setVal(parseInt(target.value, 10)) }} type="number" min={min} max={max}></Form.Control>}
        <Button onClick={() => {
            rollDice(keys, d)
        }}>{button ? label : "Roll"}{children}</Button>
    </InputGroup>
}