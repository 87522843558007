import React, { useContext, useEffect, useState } from "react"
import { ListGroup, Row, Col, Button, Form, Container, InputGroup } from "react-bootstrap"
import CharacterContext from "services/CharacterContext"
import RulesetContext from "services/RulesetContext"


export default function CharacterSkills() {
    const {character, updateCharacter} = useContext(CharacterContext)
    const {sources} = useContext(RulesetContext)
    const [skills, setSkills] = useState(character?.skills?.skills || [])
    const [skillPoints, setSkillPoints] = useState(character?.skills?.skillPoints || {current: 0, max: 0, levels: []})

    useEffect(() => {
        resetSkillPoints()
    }, [])

    const resetSkillPoints = (force = false) => {
        if (character?.id && sources) {
            const levelProgress = character?.class?.progression?.find(({level}) => level === character?.instance?.level || 1)
            if (levelProgress.skillPoints && (force || isNaN(character?.skills?.skillPoints?.max))) {
                setSkillPoints({current: levelProgress.skillPoints, max: levelProgress.skillPoints, levels: []})
            }
        }
    }

    function getModifier(character, skill) {
        if (skill.name === "Languages") {
            return parseInt(character?.attributes?.intelligence?.modifier.replace("+", "") || 0, 10)
        } else if (skill.name === "Open Doors") {
            return parseInt(character?.attributes?.strength?.modifier.replace("+", "") || 0, 10)
        } else {
            return 0
        }
    }

    useEffect(() => {
        const progressionLevel = character?.class?.progression?.find(({level}) => level === character?.level || 1)
        setSkills((sources?.skills || []).map((skill) => {
            const s = progressionLevel?.skills?.find(({name}) => name === skill.name)
            // const c = character?.skills?.skills?.find(({name}) => name === skill.name)
            skill.rank = (s?.rank || skill?.defaultValue) + getModifier(character, skill)
            return skill
        }))

    }, [sources])

    useEffect(() => {
        if (character?.id) { 
            updateCharacter(character, "skills", {skillPoints, skills})
        }
    }, [skillPoints, skills])

    if (!character) return null

    return <Container>
        <h1 className={"brand"}>Skills</h1>
        {character?.class?.name === "Specialist" && <Row>
            <Col>
                <h3 className={"brand"}>Skill Points: {skillPoints.current} / {skillPoints.max} </h3>        
            </Col>
            <Col>
                <Button onClick={resetSkillPoints}>Reset</Button>
            </Col>
        </Row>}
        <ListGroup>
            <ListGroup.Item>
                <Row>
                    <Col xs={2}>Rank</Col>
                    <Col xs={8}>Advanced Skill</Col>
                </Row>
            </ListGroup.Item>
            {skills.map((skill, i) => {
                const inSaved = character?.skills?.skills.find(({name}) => name === skill.name)
                const {name, rank} = skill
                return <ListGroup.Item key={`skills-${i}`} variant={inSaved ? "success" : "warning"} >
                    <Row>
                        <Col xs={2}>
                            <InputGroup>
                                <InputGroup.Text>{rank}</InputGroup.Text>
                                <Button disabled={skillPoints.current < 1 || skill.name === "Open Doors"} className={"form-control-sm"} onClick={() => {
                                    skill.rank++
                                    skillPoints.current--
                                    setSkillPoints({...skillPoints})
                                    setSkills([...skills])
                                }} type="number"><i className={"bi-plus"}/></Button>
                            </InputGroup>
                        </Col>
                        <Col xs={8}><Form.Text className={"form-control-sm"}>{name}</Form.Text></Col>
                    </Row>
                </ListGroup.Item>
            })}
        </ListGroup>
    </Container>
}