import React from "react"
import { Container, Col } from "react-bootstrap"
import hack from "../assets/hack.jpg"
export default function Home() {
    return <Container>
        <h1 className={"brand"}>Home</h1>
        <hr className={"border-bottom border-primary border-4 opacity-100"}></hr>
        <p>
      Welcome to ORSBeyond. Our dream is to become the open source digital toolset for the OSR Community. We love OSR games and we want to share that love with new players around the world!
        </p>
        <p>
      We plan on releasing a number of tools over the next few months, so keep coming back to see what we have so far. There are two tracks for improvements here: Rulesets and Features.
        </p>
        <div className={"d-flex flex-column flex-lg-row-reverse"}>
            <Col sm={12} lg={4}>
                <img src={hack} className="border border-primary border-4 img-fluid p-3"/>
            </Col>
            <Col>
                <h2 className={"brand"}>Upcoming Features:</h2>
                <ul>
                    <li>Character Builder and Locker</li>
                    <li>Interactive Character Sheet</li>
                    <li>Campaign Manager with Digital Invitation</li>
                    <li>Official Rules</li>
                    <li>Homebrew Creations</li>
                </ul>    
            </Col>
            <Col>
                <h2 className={"brand"}>Upcoming Rulesets:</h2>
                <ul>
                    <li>Troika!</li>
                    <li>Lamentations of the Flame Princess</li>
                    <li>Shadowdark</li>
                    <li>Knave</li>
                    <li>Tunnel Goons</li>
                    <li>Old School Essentials</li>
                    <li>Dungeon Crawl Classics</li>
                    <li>Your Favorite OSR Ruleset? <a href="/submit-ruleset">...click here to Submit!</a></li>
                </ul>
            </Col>
        </div>
        <p>The spirit of the OSR is in hacking and modifying the game to fit the table. Rulings over rules. Fun over form. This has allowed a massive growth of games to develop under the OSR banner, and we celebrate that diversity. The challenge for the OSR community as we see it now, is to make these games accessible to new players. To accomplish this task we are building custom software tools to facilitate bringing new players into the fold for your favorite tabletop role-playing games.</p>
    </Container>
}