import Form from "react-bootstrap/Form"
import CharacterContext from "services/CharacterContext"
import React, {useContext, useState, useEffect} from "react"
import { InputGroup } from "react-bootstrap"

export default function TextControl({label, sectionKey, keys}) {
    const {character, updateCharacter} = useContext(CharacterContext)
    const [val, setVal] = useState(character?.[sectionKey]?.[keys])

    useEffect(() => {
        if (character?.id) {
            const section = {...character[sectionKey]}
            section[keys] = val
            updateCharacter(character, sectionKey, section)
        }
    }, [val])

    useEffect(() => {
        if (character?.id) {
            const section = {...character[sectionKey]}
            setVal(section[keys])

        }
    }, [character])

    if (!character?.[sectionKey]?.[keys]) return null
    return <InputGroup>
        {label && <InputGroup.Text>{label}</InputGroup.Text>}
        <Form.Control value={val} onChange={({target}) => {
            setVal(target.value)
        }} type='text'></Form.Control>
    </InputGroup>
}