import DiceBox from "@3d-dice/dice-box"

let el = document.getElementById("dice-box")
if (!el) {
    el = document.createElement("div")
    el.id = "dice-box"
    document.body.appendChild(el)
}

const Dice = new DiceBox("#dice-box", {
    id: "dice-canvas",
    assetPath: "/assets/",
    theme: "wooden",
    startingHeight: 8,
    throwForce: 6,
    spinForce: 5,
    lightIntensity: 0.9
})

export {Dice}