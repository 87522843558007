import { Breadcrumb, Button, Container, Row, Col, Navbar, Card } from "react-bootstrap"
import RulesetContext from "services/RulesetContext"
import UserContext from "services/UserContext"
import NoticeContext from "services/NoticeContext"
import React, { useContext, useState, useEffect } from "react"
import ClientContext from "services/ajax"
import defaultCover from "assets/campaign-cover.png"
import { CharacterAvatar } from "components/Avatar"

Card.HeaderImage = function CardHeaderImage({height=150, width="100%", src}) {
    return <div style={{
        backgroundImage: `url(${src})`,
        backgroundSize: "cover",
        height: height,
        width: width
    }}></div>
}

export default function Campaigns() {
    const {ruleset} = useContext(RulesetContext)
    const {user} = useContext(UserContext)
    const {addNotice} = useContext(NoticeContext)
    const {getCampaigns, deleteCampaign} = useContext(ClientContext)
    const [campaigns, setCampaigns] = useState([])

    function refreshCampaigns() {
        getCampaigns(ruleset).then(campaigns => {
            setCampaigns(campaigns)
        })
    }

    useEffect(() => {
        if (ruleset) {
            refreshCampaigns()
        } else {
            addNotice("Error","Select a ruleset above to view your campaigns.", "danger")
        }
    }, [ruleset])


    return <Container className={"mb-5"}>
        <Breadcrumb>
            <Breadcrumb.Item>
                <Navbar.Brand style={{
                    backgroundImage: "url(/logo512.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    paddingLeft: "20px",
                    color: "#2c99ce",
                    fontSize: "1em"
                }}/></Breadcrumb.Item>
            <Breadcrumb.Item>{ruleset?.name}</Breadcrumb.Item>
            <Breadcrumb.Item>Campaigns</Breadcrumb.Item>
        </Breadcrumb>
        <Container className={"justify-content-between"}>
            <Row>
                <Col>
                    <h1>My Campaigns</h1>
                </Col>
            </Row>
            <Row>
                <Col className={"d-flex flex-column align-items-end"}>
                    <h3>Active Campaigns</h3>
                    { ruleset?.id && <Button onClick={() => {
                        window.location.assign("campaign/create")
                    }}>Create a Campaign</Button>}
                </Col>
            </Row>
        </Container>
        <hr className={"border-bottom border-primary border-4 opacity-100"}></hr>
        <Container fluid className={"d-flex flex-row flex-wrap"}>
            {campaigns.map((campaign) => {
                const displayDate = ((d) => {
                    return `${d.getMonth() + 1}/${d.getDate()}/${d.getYear() + 1900}`
                })(new Date(Date.parse(campaign.created_at)))
                return <Card className={"w-50 mt-2"} key={`character-${campaign.id}`} >
                    <div style={{
                        backgroundImage: `url(${campaign?.cover || defaultCover})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "150px",
                        width: "100%",
                    }}></div>
                    <Card.Body className='text-end'>
                        <div>
                            <div className={"position-absolute top-0 start-50 campaign-characters"}>
                                {campaign?.characters?.map((character) => <CharacterAvatar lg className={"mx-1"} key={`character-${character.id}`} character={character} />)}
                            </div>
                            <h3>{campaign?.name}</h3>
                            <div className={"text-secondary"}>Campaign Started {displayDate}</div>
                            <div>{campaign?.characters?.length} players</div>
                            <div>ROLE: {user.public === campaign?.owner_id ? "GAME MASTER" : "PLAYER"}</div>
                        </div>
                        <Button className='ms-2' href={`/campaign/${campaign.slug}`}>View</Button>
                        <Button className='ms-2' href={`/campaign/${campaign.slug}/edit`}>Edit</Button>
                        {user.id === campaign.owner_id && <Button className='ms-2' onClick={() => console.log("check if they really want to deactivate")}>Deactivate</Button>}
                        <Button className='ms-2' variant="danger" onClick={() => {
                            deleteCampaign(campaign).then(refreshCampaigns)
                        } }><i className='bi-trash' /></Button>
                    </Card.Body>
                </Card>
            })}
        </Container>
    </Container>
}