import React, { useState, lazy, Suspense, useContext, useEffect } from "react"
import { Container, Row, Col, Button, Placeholder } from "react-bootstrap"
import { useParams } from "react-router-dom"
import RulesetContext from "services/RulesetContext"
import ClientContext from "services/ajax" 
import { newCharacter } from "components/lotfp/CreateCharacter"
import UserContext from "services/UserContext"

export default function JoinCampaign() {
    const {ruleset} = useContext(RulesetContext)
    const {user} = useContext(UserContext)
    const [campaign, setCampaign] = useState(null)
    const [characters, setCharacters] = useState([])
    const [selectedCharacter, setSelectedCharacter] = useState(null)
    const {slug} = useParams()
    const {getCampaign, getCharacters, createCharacter, joinCampaign } = useContext(ClientContext)
    useEffect(() => {
        getCampaign({slug}).then(c => setCampaign(c))
        getCharacters(ruleset).then(c => setCharacters(c))
    }, [])

    const CharacterCard = lazy(() => import(`components/${ruleset.id}/CharacterCard`))


    return <Container>
        <h1>Join a Campaign</h1>
        <hr className={"border-bottom border-primary border-4 opacity-100"}></hr>
        <Container className="text-center">
            <Row>
                <Col>
                    <h1>{campaign?.name}</h1>
                    <p>{campaign?.owner?.name} has invited you to join {campaign?.name}. Please select the character you want to join with below or,</p>
                    <div><Button className={"btn-primary btn-lg"} onClick={() => {
                        const character = newCharacter(user)
                        createCharacter(ruleset.id, character).then((c) => {
                            joinCampaign(campaign, c).then(() => {
                                window.location.assign(`/characters/builder/${ruleset.id}/standard/${c.id}/0`)
                            })
                        })
                    }}>Create a Character</Button></div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <strong>Available Characters in Campaign</strong>
                    <p>You can join by claiming an unassigned character if you have character slots available.</p>
                    <div>Character Holder</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <strong>My Characters</strong>
                    <Suspense fallback={<Placeholder/>}>
                        <div className={"d-flex flex-row"}>
                            {characters.map((character) => {
                                return <CharacterCard key={character.id} character={character}><Button onClick={() => setSelectedCharacter(character)} disabled={character.campaign_id}>Select</Button></CharacterCard>
                            })}
                        </div>

                    </Suspense>
                    <Button disabled={!selectedCharacter} onClick={() => {
                        joinCampaign(campaign, selectedCharacter).then(() => {
                            window.location.assign(`/campaign/${campaign.slug}`)
                        })
                    }} className={"btn-lg btn-primary mt-3"}>Join with this Character</Button>
                </Col>
            </Row>
        </Container>
    </Container>
}